import { Injectable } from "@angular/core";
import { HttpService } from "src/app/services/http.service";
import { InvestorContactReqRes } from "../../contacts/models/investorContactReqRes.model";
import { RecipientsGroup } from "./recipients-group";
import { InvestorSuggestion } from "./investor-suggestion";
import { RecipientType } from "./recipient-type";

@Injectable({
  providedIn: "root",
})
export class InvestorCommunicationService {
  public readonly INVESTORCOMMUNICATION_BASE_ENDPOINT = "investorcommunication";
  private readonly MAX_NUM_OF_RECIPIENTS = 2000;

  constructor(private http: HttpService) {}

  getGroups(filterQuery?: string) {
    return this.http.getTyped<RecipientsGroup[]>(
      `${this.INVESTORCOMMUNICATION_BASE_ENDPOINT}/getgroups${
        filterQuery ? `?filter=${filterQuery}` : ""
      }`
    );
  }

  sendInvestorsMails(templateId: number, model: any) {
    return this.http.post(
      `${this.INVESTORCOMMUNICATION_BASE_ENDPOINT}/${templateId}`,
      model
    );
  }

  generateSubmitModel(formValues: {
    recipients: InvestorSuggestion[];
    bccRecipients: InvestorSuggestion[];
    subject: string;
    message: string;
  }) {
    const recipientsByType = [
      ...formValues.recipients,
      ...formValues.bccRecipients,
    ].reduce(
      (acc, val) => ({
        ...acc,
        [val.recipientType]: acc[val.recipientType]
          ? [
              ...acc[val.recipientType],
              val.recipientType !== RecipientType.Group ? val.id : val,
            ]
          : [val.recipientType !== RecipientType.Group ? val.id : val],
      }),
      {}
    );
    return {
      ...formValues,
      message: formValues.message || null,
      recipients: recipientsByType[RecipientType.Contact],
      users: recipientsByType[RecipientType.User],
      groups: recipientsByType[RecipientType.Group],
    };
  }
}
