import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from "@angular/core";
import { SelectionModel } from "@angular/cdk/collections";
import { UntypedFormControl } from "@angular/forms";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { merge, Observable, EMPTY, combineLatest } from "rxjs";
import {
  debounceTime,
  tap,
  shareReplay,
  map,
  take,
  switchMap,
  switchMapTo,
} from "rxjs/operators";
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from "@w11k/ngx-componentdestroyed";

import { InvestorContactReqRes } from "../../models/investorContactReqRes.model";
import { ContactDataService } from "src/app/services/gp/contact-data.service";
import { InvestorContactDataSource } from "./investorContactDataSource";
import { InvestorContactsTableService } from "./investor-contacts-table.service";
import { PermissionService } from "src/app/permission/permission.service";
import { ViewAsInvestorPermission } from "../../../../permission/enums/view-as-investor-permission";
import { NoPermissionAction } from "src/app/shared/directives/check-permissions.directive";

@Component({
  selector: "terra-investor-contacts-display-table",
  templateUrl: "./investor-contacts-display-table.component.html",
  styleUrls: [
    "./investor-contacts-display-table.component.scss",
    "../../contacts.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestorContactsDisplayTableComponent
  extends OnDestroyMixin
  implements OnInit
{
  allowInvestorName$ = this.permissionService.allowInvestorName$;
  isAllContactsVisible$ = this.permissionService.isAllContactsVisible$;

  @Input() showMessageWhenListIsEmpty = true;
  @Input() isLoadingViewAsInvestor$: Observable<boolean>;

  @Output() editRow: EventEmitter<number> = new EventEmitter();
  @Output() deleteRow: EventEmitter<number[]> = new EventEmitter();
  @Output() viewAsInvestor: EventEmitter<InvestorContactReqRes> =
    new EventEmitter();
  @Output() selectedRows: EventEmitter<InvestorContactReqRes[]> =
    new EventEmitter();

  ViewAsInvestorPermission = ViewAsInvestorPermission;
  NoPermissionAction = NoPermissionAction;
  contactPerPageOptions = [25, 50, 100, 200, 500];
  perPageControl = new UntypedFormControl(this.contactPerPageOptions[0]);
  perPageChanged$: Observable<number> = this.perPageControl.valueChanges.pipe(
    untilComponentDestroyed(this),
    debounceTime(200)
  );

  get selectedContacts() {
    if (!this.selection) {
      return [];
    }
    return this.selection.selected;
  }

  dataSource = new InvestorContactDataSource(
    this.contactDataService,
    this.contactsTableService
  );

  searchOptions$ = this.contactsTableService.searchOptions$;

  pageRowsCount$ = this.dataSource.pageRows$.pipe(
    map((rows) => (rows ? rows.length : 0)),
    shareReplay(1)
  );
  totalRowsCount$ = this.dataSource.totalRowsCount$;
  isLoading$: Observable<boolean>;

  selection = new SelectionModel<InvestorContactReqRes>(true, []);
  displayedColumns: string[] = [
    "select",
    "name",
    "email",
    "createTimestamp",
    "investingEntityAccountingExternalIdList",
    "tags",
    "personalRemarks",
    "action",
  ];

  isAllSelected$ = merge(this.selection.changed, this.pageRowsCount$).pipe(
    switchMapTo(this.pageRowsCount$),
    map((pageRowsCount) => pageRowsCount === this.selection.selected.length),
    shareReplay(1)
  );

  constructor(
    private contactsTableService: InvestorContactsTableService,
    private contactDataService: ContactDataService,
    private permissionService: PermissionService
  ) {
    super();
  }

  ngOnInit() {
    this.isLoading$ = combineLatest([
      this.dataSource.isLoading$,
      this.isLoadingViewAsInvestor$,
    ]).pipe(
      map(
        ([isLoadingData, isLoadingViewAsInvestor]) =>
          isLoadingData || isLoadingViewAsInvestor
      )
    );
    this.contactsTableService.updatePageSize(this.contactPerPageOptions[0]);

    this.perPageChanged$
      .pipe(untilComponentDestroyed(this))
      .subscribe((pageSize) => {
        this.contactsTableService.updatePageSize(pageSize);
      });

    this.dataSource.pageRows$
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.clearSelection();
      });

    this.selection.changed
      .pipe(
        untilComponentDestroyed(this),
        tap((_) => this.selectedRows.emit(this.selectedContacts))
      )
      .subscribe();
  }

  trackByFunction(index: number, item: InvestorContactReqRes) {
    return item.id;
  }

  pageChange(event: PageEvent) {
    this.contactsTableService.updatePageNumber(event.pageIndex);
  }

  rowCheckToggle(
    row: InvestorContactReqRes,
    unselectOthers = false,
    event = null
  ) {
    if (event && (event.ctrlKey || event.metaKey)) {
      unselectOthers = false;
    }

    const isRowChecked = this.isRowChecked(row);
    const currentNumberOfSelectedItems = this.selectedContacts.length;

    if (unselectOthers) {
      this.clearSelection();
    }

    if (
      isRowChecked &&
      (currentNumberOfSelectedItems === 1 || !unselectOthers)
    ) {
      this.selection.deselect(row);
    } else {
      this.selection.select(row);
    }
  }

  isRowChecked(row: InvestorContactReqRes) {
    return this.selection.selected.some((x) => x.id === row.id);
  }

  masterToggle() {
    this.isAllSelected$
      .pipe(
        take(1),
        switchMap((isAllSelected) => {
          if (isAllSelected) {
            this.selection.clear();
            return EMPTY;
          } else {
            return this.dataSource.pageRows$;
          }
        }),
        tap((rowsToSelect) => {
          this.selection.select(...rowsToSelect);
        }),
        take(1)
      )
      .subscribe();
  }

  // Check if all rows are selected
  deleteMulitple() {
    if (this.selection.selected.length === 0) {
      return;
    }

    const ids = this.selection.selected.map((i) => i.id);

    this.deleteRow.emit(ids);
  }

  onEditRow(id: number) {
    if (!this.editRow) {
      return;
    }
    this.editRow.emit(id);
  }

  onViewAsInvestor(contact: InvestorContactReqRes) {
    if (!this.viewAsInvestor) {
      return;
    }

    this.viewAsInvestor.emit(contact);
  }

  onDeleteRow(id: number) {
    if (!this.deleteRow) {
      return;
    }
    this.deleteRow.emit([id]);
  }

  showActions(model: InvestorContactReqRes) {
    if (this.selection.selected.length === 1) {
      if (model.id === this.selection.selected[0].id) {
        return true;
      }
      return false;
    }

    if (model.hover) {
      return true;
    }
    return false;
  }

  sortData(sort: Sort) {
    if (!sort.active || !sort.direction) {
      return;
    }
    this.contactsTableService.sort(sort.active, sort.direction);
  }

  private clearSelection() {
    this.selection.clear();
  }
}
