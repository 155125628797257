<ng-container *ngIf="{
  searchOptions: searchOptions$ | async,
  pageRowsCount: pageRowsCount$ | async,
  totalRowsCount : totalRowsCount$ | async} as data">

  <!-- Summary + page size selector -->
  <div class="results-pane-title" *ngIf="data.totalRowsCount > 0">
    <div>Showing {{ data.pageRowsCount }} of {{ data.totalRowsCount }} contacts</div>
    <mat-form-field appearance="outline" class="remove-error-place">
      <mat-select [formControl]="perPageControl">
        <mat-option *ngFor="let amountPerPage of contactPerPageOptions" [value]="amountPerPage">{{ amountPerPage }} per page
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- Data table -->
  <div class="hide-investor-name-message-sort" *ngIf="!(allowInvestorName$ | async) && data.totalRowsCount > 0">To find a specific contact by ID please use the Advanced Search field above
  </div>
  <table mat-table [dataSource]="dataSource" [trackBy]="trackByFunction" class="gray-theme mat-elevation-z0" matSort matSortDisableClear
         (matSortChange)="sortData($event)" [matSortDirection]="(searchOptions$ | async).sortOrder"
         [matSortActive]="(searchOptions$ | async).orderBy">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef class="gray-theme checkbox-col-header">
       <div class="flex flex-row align-items-center gap-1">
        <mat-checkbox (change)="$event ? masterToggle() : null" [indeterminate]="selection.hasValue() && !(isAllSelected$ | async)"
                      [checked]="selection.hasValue() && (isAllSelected$ | async)" [disabled]="data.pageRowsCount === 0" color="primary">
        </mat-checkbox>

        <button mat-icon-button class="header-delete-button" (click)="deleteMulitple()"
                *ngIf="deleteRow && deleteRow.observers.length > 0 && selection.selected.length > 0">
          <mat-icon class="notranslate">delete</mat-icon>
        </button>
      </div>
      </th>
      <td mat-cell class="gray-theme" *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? rowCheckToggle(row) : null" [checked]="isRowChecked(row)"
                      color="accent"></mat-checkbox>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="FirstName" [disabled]="!(allowInvestorName$ | async)" class="name-col-header">Name</th>
      <td mat-cell *matCellDef="let element" class="lo_sensitive name-cell">
        <ng-container *ngIf="allowInvestorName$ | async">
          <div class="full-name">{{ element.firstName | truncate: 30 }}
            {{ element.middleName | truncate: 20 }}
            {{ element.lastName | truncate: 30 }}
          </div>
          <div *ngIf="!(isAllContactsVisible$ | async)" class="nickname">Entity {{ element.nickname | truncate: 10 }}</div>
        </ng-container>
        <ng-container *ngIf="!(allowInvestorName$ | async)">
          {{ element.nickname | truncate: 10 }}
        </ng-container>
      </td>
    </ng-container>

    <!-- Email Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="Email" [disabled]="!(allowInvestorName$ | async)" class="email-col-header">Email</th>
      <td mat-cell *matCellDef="let element" class="lo_sensitive email-cell">
        {{ element.email }}
      </td>
    </ng-container>

    <!-- Created at Column -->
    <ng-container matColumnDef="createTimestamp">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="CreateTimestamp" class="createtimestamp-col-header">Created</th>
      <td mat-cell *matCellDef="let element" class="lo_sensitive createtimestamp-cell padding-right-cell">
        {{ element.createTimestamp | date:'longDate' }}
      </td>
    </ng-container>

    <!-- InvestingEntityAccountingExternalIdList Column -->
    <ng-container matColumnDef="investingEntityAccountingExternalIdList">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="investingEntityAccountingExternalIdList" class="account-ids-col-header">
        Investing Entity <br/>Accounting IDs
      </th>
      <td mat-cell *matCellDef="let element" class="lo_sensitive email-cell">
        {{ element.investingEntityAccountingExternalIdList }}
      </td>
    </ng-container>

    <!-- Tags Column -->
    <ng-container matColumnDef="tags">
      <th mat-header-cell *matHeaderCellDef class="tags-col-header">Tags</th>
      <td mat-cell *matCellDef="let element" class="tags-column-content">
        <mat-chip-set class="terra-chip">
          <mat-chip *ngFor="let c of element.contactTags" class="lo_sensitive">
            {{ c }}
          </mat-chip>
        </mat-chip-set>
      </td>
    </ng-container>

    <!-- PersonalRemarks Column -->
    <ng-container matColumnDef="personalRemarks">
      <th mat-header-cell *matHeaderCellDef class="remarks-col-header">Remarks</th>
      <td mat-cell *matCellDef="let element" matTooltipClass="terra-tooltip-compact"
          [matTooltip]="element.personalRemarks?.length > 50 ? element.personalRemarks : null" class="lo_sensitive">
        {{ element.personalRemarks | truncate: 50 }}
      </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="actions-col-header"></th>
      <td mat-cell *matCellDef="let contact" class="actions" [class.hover]="showActions(contact)">
        <button *ngIf="viewAsInvestor" mat-icon-button class="view-as-investor-button" terraCheckPermissions
                [minimumViewAsInvestorPermission]="ViewAsInvestorPermission.Allowed" [noPermissionAction]="NoPermissionAction.PopupOnClick"
                (click)="onViewAsInvestor(contact); $event.stopPropagation(); (false)" disableRipple matTooltip="View as Investor"
                matTooltipClass="terra-tooltip-compact">
          <mat-icon class="edit-icon notranslate">remove_red_eye</mat-icon>
        </button>
        <button *ngIf="editRow && editRow.observers.length > 0" mat-icon-button class="edit-button"
                (click)="onEditRow(contact.id); $event.stopPropagation(); (false)" disableRipple matTooltip="Edit"
                matTooltipClass="terra-tooltip-compact">
          <mat-icon class="edit-icon notranslate">edit</mat-icon>
        </button>
        <button *ngIf="!contact.isAgentContact && deleteRow && deleteRow.observers.length > 0" mat-icon-button class="delete-button"
                (click)="onDeleteRow(contact.id); $event.stopPropagation(); (false)" disableRipple matTooltip="Delete"
                matTooltipClass="terra-tooltip-compact">
          <mat-icon class="delete-icon notranslate">delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

    <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.selected-row]="isRowChecked(row)" [class.hide]="isLoading$ | async"
        (click)="rowCheckToggle(row)" (mouseover)="row.hover = true" (mouseout)="row.hover = false" class="value-row"></tr>
  </table>

  <!-- loader  -->
  <terra-loader *ngIf="isLoading$ | async"></terra-loader>

  <!-- Empty state -->
  <div class="empty-list-message" *ngIf="!(isLoading$ | async) && data.totalRowsCount == 0 && showMessageWhenListIsEmpty">
    <p>Add a new contact or import your contact list</p>
  </div>

  <mat-paginator (page)="pageChange($event)" [length]="data.totalRowsCount" [hidePageSize]="true" [pageSize]="data.searchOptions.pageSize"
                 [pageIndex]="data.searchOptions.pageNumber" *ngIf="data.totalRowsCount > 0"></mat-paginator>
</ng-container>
