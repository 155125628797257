import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {ContactsComponent} from './contacts.component';
import {InvestorContactsDisplayTableComponent} from './components/investor-contacts-display-table/investor-contacts-display-table.component';
import {InvestorContactDialogComponent} from './components/investor-contact-dialog/investor-contact-dialog.component';
import {InvestorContactImportDialogComponent} from './components/investor-contact-import-dialog/investor-contact-import-dialog.component';
import {InvestingEntitiesDisplayTableComponent} from './components/investing-entities-display-table/investing-entities-display-table.component';
import {InvestorContactDetailsTabComponent} from './components/investor-contact-dialog/investor-contact-details-tab/investor-contact-details-tab.component';
import {InvestorContactInvestingEntitiesTabComponent} from './components/investor-contact-dialog/investor-contact-investing-entities-tab/investor-contact-investing-entities-tab.component';
import {EditInvestingEntityDialogComponent} from './components/investor-contact-dialog/edit-investing-entity-dialog/edit-investing-entity-dialog.component';
import {ContactReferrersDialogComponent} from './components/contact-referrers-dialog/contact-referrers-dialog.component';
import {InvestingEntityViewDialogComponent} from './components/investing-entity-view-dialog/investing-entity-view-dialog.component';
import {AdditionalEmailsDialogComponent} from './components/investor-contact-dialog/investor-contact-details-tab/additional-emails-dialog/additional-emails-dialog.component';
import {InvestorsContactsComponent} from './investors-contacts/investors-contacts.component';
import {RecipientsContactsComponent} from './recipients-contacts/recipients-contacts.component';
import {RecipientContactsDisplayTableComponent} from '../../shared/components/recipient-contacts-display-table/recipient-contacts-display-table.component';
import {CreateEditRecipientDialogComponent} from './components/create-edit-recipient-dialog/create-edit-recipient-dialog.component';
import {ContactTagsComponent} from './components/contact-tags/contact-tags.component';
import {InvestorContactReferrerDialogComponent} from './components/investor-contact-dialog/investor-contact-referrer-dialog/investor-contact-referrer-dialog.component';
import {APP_SETTINGS, USER_DETAILS} from 'telemetry-library';
import {UserService} from '../../services/shared/user.service';
import {AppSettingsService} from '../../services/app-settings.service';
import {SharedModule} from '../../shared/shared.module';
import {MaterialModule} from '../../shared/material.module';
import {CovercySharedComponentsModule} from '../../shared/CovercySharedComponents.module';
import { AccreditationModule } from '../shared/accreditation/accreditation.module';
import { GoogleMapSearchModule } from 'src/app/shared/components/google-map-search/google-map-search.module';
import { ViewAsInvestorContactsComponent } from './components/view-as-investor-contacts/view-as-investor-contacts.component';
import { ContactsEmailTrackingComponent } from './contacts-email-tracking/contacts-email-tracking.component';
import { IntegrationRequestDialogComponent } from '../shared/integration-request-dialog/integration-request-dialog.component';


@NgModule({
  declarations: [
    ContactsComponent,
    InvestorContactsDisplayTableComponent,
    InvestorContactDialogComponent,
    InvestorContactImportDialogComponent,
    InvestingEntitiesDisplayTableComponent,
    InvestorContactDetailsTabComponent,
    InvestorContactInvestingEntitiesTabComponent,
    EditInvestingEntityDialogComponent,
    InvestorContactReferrerDialogComponent,
    ContactReferrersDialogComponent,
    InvestingEntityViewDialogComponent,
    AdditionalEmailsDialogComponent,
    InvestorsContactsComponent,
    RecipientsContactsComponent,
    CreateEditRecipientDialogComponent,
    ContactTagsComponent,
    ViewAsInvestorContactsComponent,
    ContactsEmailTrackingComponent
  ],
  exports: [
    InvestingEntitiesDisplayTableComponent,
    ViewAsInvestorContactsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    CovercySharedComponentsModule,
    AccreditationModule,
    GoogleMapSearchModule,
    IntegrationRequestDialogComponent
  ],
  providers: [
    {provide: USER_DETAILS, useExisting: UserService},
    {provide: APP_SETTINGS, useExisting: AppSettingsService}
  ]
})
export class ContactsModule { }
