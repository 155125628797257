import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from "@angular/core";
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";

@Component({
  selector: "terra-investor-communication-result-snackbar",
  templateUrl: "./investor-communication-result-snackbar.component.html",
  styleUrls: ["./investor-communication-result-snackbar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestorCommunicationResultSnackbarComponent implements OnInit {
  messageToDisplay: string;
  icon: string;
  successMessage = "Email successfully sent";
  failedMessage = "Email failed to be sent";
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: { result: boolean }) {}

  ngOnInit(): void {
    this.messageToDisplay = this.data.result
      ? this.successMessage
      : this.failedMessage;

    this.icon = this.data.result ? "check_circle_outline" : "error";
  }
}
