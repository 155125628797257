<div class="view-investor-container">
  <div class="search-container">
    <terra-search-field
      placeholder="Search investors by name or email"
      (filterChange)="filterChanged($event)"
      (clearSearch)="clearSearch()"
    />
    <span>Showing {{ totalCount }} Investors</span>
  </div>

  <ul
    class="list-group"
    #uiElement
    *ngIf="!(isLoading$ | async)"
    (scroll)="onScrollLoadData()"
  >
    <li class="list-group-item" *ngFor="let contact of contacts">
      <div class="content">
        <terra-name-icon [names]="[contact.firstName, contact.lastName]" />
        <div class="text">
          <span class="name">{{ contact.firstName + " " + contact.lastName | truncate : 42 : true }}</span>
          <span class="email">{{ contact.email | truncate : 47 : true }}</span>
        </div>
      </div>
      <terra-buttons
        type="small"
        label="View as investor"
        (onClick)="viewAsInvestorBtn(contact)"
      />
    </li>
  </ul>
  <terra-loader *ngIf="isLoading$ | async"/>
</div>
