import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';

import {PhoneVerificationReqRes} from 'src/app/shared/components/phone-verification-dialog/PhoneVerificationReqRes.model';
import {LoggerService} from 'src/app/shared/errors/logger.service';
import {SearchOptionsRequest} from 'src/app/shared/models/SearchOptionsRequest.model';
import {SearchOptionsResponse} from 'src/app/shared/models/SearchOptionsResponse.model';
import {AbstractRestService} from 'src/app/shared/types/AbstractRestService';
import {HttpService} from '../http.service';
import {PhoneVerificationReq} from '../../shared/components/phone-verification-dialog/PhoneVerificationReq.model';
import {InvestingEntityOwnershipReqRes} from '../../dashboard/models/InvestingEntityOwnershipReqRes.model';
import { UnitApplicationValidationLevel } from 'src/app/shared/models/unit-bank-account/CreateUpdateDraftUnitApplication.model';
import { UnitBankApplicationDataService } from '../shared/unit-bank-application-data.service';
import { UnitBusinessApplicationReqRes } from 'src/app/shared/models/unit-bank-account/UnitBusinessApplicationReqRes.model';
import { UnitDocumentReqRes } from 'src/app/shared/models/unit-bank-account/UnitDocumentReqRes.model';



@Injectable()
export class BankApplicationDataService extends AbstractRestService<UnitBusinessApplicationReqRes, UnitBusinessApplicationReqRes> implements UnitBankApplicationDataService {

  readonly baseEndpoint$ = of('cre-applications/');

  constructor(public http: HttpService, private loggerService: LoggerService) {
    super(http);
  }

  public getList(
    options: SearchOptionsRequest
  ): Observable<SearchOptionsResponse<UnitBusinessApplicationReqRes>> {
    return super.getList(options);
  }

  createDraft(model: UnitBusinessApplicationReqRes, validationLevel: UnitApplicationValidationLevel = 'draft'):Observable<UnitBusinessApplicationReqRes> {
    return this.baseEndpoint$.pipe(
      switchMap(baseUrl => this.http.postTyped<UnitBusinessApplicationReqRes>(
        `${baseUrl}?validationLevel=${validationLevel}`, model)));
  }

  updateDraft(applicationId: number, model: UnitBusinessApplicationReqRes, validationLevel: UnitApplicationValidationLevel = 'draft'):Observable<UnitBusinessApplicationReqRes> {
    return this.baseEndpoint$.pipe(
      switchMap(baseUrl => this.http.putTyped<UnitBusinessApplicationReqRes>(
        `${baseUrl}${applicationId}?validationLevel=${validationLevel}`, model)));
  }

  sendToUnit(applicationId: number, model: UnitBusinessApplicationReqRes):Observable<UnitBusinessApplicationReqRes> {
    return this.baseEndpoint$.pipe(
      switchMap(baseUrl => this.http.postTyped<UnitBusinessApplicationReqRes>(
        `${baseUrl}${applicationId}/send`, model)));
  }

  sendVerificationCode(applicationId: number): Observable<PhoneVerificationReqRes> {
    return this.baseEndpoint$.pipe(
      switchMap(baseUrl => this.http.postTyped<PhoneVerificationReqRes>(
        `${baseUrl}${applicationId}/phone-verification/send-code`, null)));
  }

  resendVerificationCode(applicationId: number): Observable<PhoneVerificationReqRes> {
    return this.baseEndpoint$.pipe(
      switchMap(baseUrl => this.http.postTyped<PhoneVerificationReqRes>(
        `${baseUrl}${applicationId}/phone-verification/resend-code`, null)));
  }

  verifyPhone(applicationId: number, request: PhoneVerificationReq): Observable<PhoneVerificationReqRes> {
    return this.baseEndpoint$.pipe(
      switchMap(baseUrl => this.http.postTyped<PhoneVerificationReqRes>(
        `${baseUrl}${applicationId}/phone-verification/verify`, request)));
  }

  /** If a file was uploaded, attach it to the relevant document and sent it to Unit for review. If the file was removed, delete it from the document entity. */
  updateDocument(applicationId: number, documentId: number, metaFileLinkId: number, backSideMetaFileLinkId: number,) {
    return this.baseEndpoint$.pipe(
      switchMap(baseUrl =>
        this.http.putTyped<void>(`${baseUrl}${applicationId}/documents/${documentId}`,
          {
            metaFileLinkId: metaFileLinkId,
            backSideMetaFileLinkId: backSideMetaFileLinkId
          } as UnitDocumentReqRes)
      ),
      catchError(error => {
        this.loggerService.error(`Error in BankApplicationService => updateDocument(). documentId: ${documentId} metaFileLinkId: ${metaFileLinkId}`, error);
        return throwError(()=>error);
      })
    );
  }

  /**
   * Gets investing entities with more than 25% ownership in the holding
   * @param holdingId holding id
   */
  getAutoGeneratedBeneficialOwners(holdingId: number): Observable<InvestingEntityOwnershipReqRes[]> {
    return this.baseEndpoint$.pipe(
      switchMap(baseUrl => this.http.getTyped<InvestingEntityOwnershipReqRes[]>(`${baseUrl}beneficial-owners/holding/${holdingId}`)),
      catchError(err => {
        this.loggerService.error(`Error getting auto generated beneficial owners for holding id: ${holdingId}`, err);
        return throwError(()=>err);
      })
    );
  }

  clientHasApprovedUnitApplication(): Observable<boolean>{
    return this.baseEndpoint$.pipe(
      switchMap(base => this.http.getTyped<boolean>(`${base}has-approved-application`)),
      catchError(err => {
        return of(false);
      })
    );
  }

  getOrganizationApplication(): Observable<UnitBusinessApplicationReqRes>{
    return this.baseEndpoint$.pipe(
      switchMap(baseUrl => this.http.getTyped<UnitBusinessApplicationReqRes>(
        `${baseUrl}current-organization`)));
  }

  getAvailableOrganizationLevelApplications():Observable<UnitBusinessApplicationReqRes[]>{
    return this.baseEndpoint$.pipe(
      switchMap(baseUrl => this.http.getTyped<UnitBusinessApplicationReqRes[]>(
        `${baseUrl}organization-applications`)));
  }
}
