import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { HttpService } from "src/app/services/http.service";
import { AbstractRestService } from "src/app/shared/types/AbstractRestService";
import { TemplateModel } from "./template-model";

@Injectable({
  providedIn: "root",
})
export class TemplateService extends AbstractRestService<
  TemplateModel,
  TemplateModel
> {
  protected readonly baseEndpoint$ = of("template/");

  constructor(http: HttpService) {
    super(http);
  }
}
