import {Component, OnInit, ChangeDetectionStrategy, Inject} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {finalize, switchMap, take} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {MatChipInputEvent} from '@angular/material/chips';

import {GpRecipientContactsDataService} from '../../../../services/gp/gp-recipient-contacts-data.service';
import {fadeInOut} from '../../../../shared/animations';
import {TerraUtils} from '../../../../shared/TerraUtils';
import {LoggerService} from '../../../../shared/errors/logger.service';
import {CustomValidators} from '../../../../shared/validators/custom.validators';
import {RecipientCreateDto} from 'src/app/shared/components/unit/unit-payment/models/recipient/recipient-create-dto.model';
import {DestinationBankAccountCreateDto} from 'src/app/shared/components/unit/unit-payment/models/recipient/recipient-bank-account-create-dto.model';
import {PaymentType} from 'src/app/shared/enums/payment-type.enum';
import HttpStatusCodesEnum from 'src/app/shared/enums/HttpStatusCodesEnum';
import {AddressFields, AddressFormSettings} from 'src/app/shared/components/address-form/address-form.settings';
import {LocationDetailsResponse} from 'src/app/shared/models/LocationDetailsResponse.model';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import CheckingOrSavingsType from '../../../../shared/enums/CheckingOrSavingsType.enum';

@Component({
  selector: 'terra-create-edit-recipient-dialog',
  templateUrl: './create-edit-recipient-dialog.component.html',
  styleUrls: ['./create-edit-recipient-dialog.component.scss'],
  animations: [fadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateEditRecipientDialogComponent extends OnDestroyMixin implements OnInit {
  isLoading$ = new BehaviorSubject<boolean>(false);
  isSubmitted$ = new BehaviorSubject<boolean>(false);
  isGeneralServerError$ = new BehaviorSubject<boolean>(false);
  validationError$ = new BehaviorSubject<boolean>(false);
  isUpdatingExistingRecipient$ = new BehaviorSubject<boolean>(false);

  pageForm: UntypedFormGroup;
  currentTags = [] as string[];
  generalErrorMessage = TerraUtils.consts.messages.GENERAL_SUBMIT_ERROR_WITH_LINK;
  CrePaymentType = PaymentType;
  forbiddenNameCharacters = TerraUtils.consts.validators.FORBIDDEN_CHARACTERS_IN_NAME;
  memoTooltipText = 'This Memo / Special Instruction also known as, OBI (Originating Beneficiary Information), "Message/Reference to Beneficiary" will be sent along with the wire information and will inform the beneficiary as to additional instructions (for example, which customer account number to apply the funds to).  If you are wiring to a brokerage firm that uses a bank to receive funds, such as National Financial Services or Charles Schwab, enter the "For Further Credit" (FFC) information in this field to ensure that your funds are applied to the proper brokerage account.';
  memoLength = TerraUtils.consts.validators.MEMO_MAX_LENGTH;
  recipientLocation: LocationDetailsResponse;

  CheckingOrSavingsType = CheckingOrSavingsType;
  checkingOrSavingsTypes = CheckingOrSavingsType.listAll();

  constructor(private fb: UntypedFormBuilder,
              @Inject(MAT_DIALOG_DATA) public recipientId: number,
              private dialogRef: MatDialogRef<CreateEditRecipientDialogComponent>,
              private recipientContactsDataService: GpRecipientContactsDataService,
              private loggerService: LoggerService
  ) {
    dialogRef.addPanelClass('terra-create-edit-recipient-dialog');
    super();
  }

  ngOnInit(): void {
    this.generateForm();
    if (!!this.recipientId) {
      this.isUpdatingExistingRecipient$.next(true);
      this.populateForm(this.recipientId);
    }
  }

  get generateAddressFormSettings(): AddressFormSettings {
    // tslint:disable-next-line:no-bitwise
    const fieldsToShow = AddressFields.Country | AddressFields.Street | AddressFields.Street2 | AddressFields.City | AddressFields.State | AddressFields.PostalCode;
    return new AddressFormSettings(fieldsToShow, undefined, true);
  }

  /**
   * Generates an empty form
   */
  private generateForm() {
    this.pageForm = this.fb.group({
      firstName: [null, [Validators.required, CustomValidators.containsForbiddenCharacterValidator(this.forbiddenNameCharacters),
        Validators.maxLength(TerraUtils.consts.validators.FIRSTNAME_LENGTH)]],
      lastName: [null, [CustomValidators.containsForbiddenCharacterValidator(this.forbiddenNameCharacters),
        Validators.maxLength(TerraUtils.consts.validators.LASTNAME_LENGTH)]],
      tags: [''],
      memo: [null, [Validators.maxLength(this.memoLength)]],
      accountType: [null, [Validators.required]],
      routingNumber: [null, [Validators.required, Validators.pattern(`^\\d{9}$`)]],
      accountNumber: [null, [Validators.required, Validators.pattern(`^[0-9]*$`)]],
      address: {},
      checkingOrSavingsType: CheckingOrSavingsType.Checking
    });

    this.pageForm.get('accountType').valueChanges.pipe(
      untilComponentDestroyed(this)
    ).subscribe(accountType => {
      accountType === PaymentType.Ach ? this.pageForm.get('address').disable() : this.pageForm.get('address').enable();
    });
  }

  /**
   * Creates RecipientCreateDto from form values
   */
  private generateSubmitModel(): RecipientCreateDto {
    const formValues = this.pageForm.getRawValue();
    const model = new RecipientCreateDto();
    model.firstName = formValues.firstName;
    model.lastName = formValues.lastName || '';
    model.tags = this.currentTags;
    model.memo = formValues.memo;
    model.bankAccount = new DestinationBankAccountCreateDto();

    model.bankAccount.usAbaRoutingNumber = formValues.routingNumber;
    model.bankAccount.accountNumber = formValues.accountNumber;
    model.bankAccount.paymentType = formValues.accountType;
    model.bankAccount.checkingOrSavingsType = formValues.checkingOrSavingsType;

    if (formValues.accountType === PaymentType.Wire) {
      model.location = {...formValues.address} as LocationDetailsResponse;
    }

    return model;
  }

  /**
   * Sends RecipientCreateDto to the server
   */
  save() {
    this.isSubmitted$.next(false);
    this.isGeneralServerError$.next(false);
    this.pageForm.markAllAsTouched();
    if (!this.pageForm.valid) {
      this.isSubmitted$.next(true);
      return;
    }

    this.isSubmitted$.next(true);
    this.isLoading$.next(true);
    const submitModel = this.generateSubmitModel();
    this.isUpdatingExistingRecipient$.pipe(
      take(1),
      switchMap(isUpdate => {
        if (isUpdate) {
          return this.recipientContactsDataService.updateRecipient(this.recipientId, submitModel);
        } else {
          return this.recipientContactsDataService.createRecipient(submitModel);
        }
      }),
      finalize(() => {
        this.isLoading$.next(false);
      })
    ).subscribe(_ => {
        this.dialogRef.close(true);
      },
      error => {
        if (error.status == HttpStatusCodesEnum.BAD_REQUEST) {
          this.isGeneralServerError$.next(false);
          this.validationError$.next(error.error);
        } else {
          this.isGeneralServerError$.next(true);
          this.loggerService.error(`An error occurred creating a recipient`, [submitModel, error]);
        }
      });
  }

  /**
   * Adds a new tag
   * @param event The event of tag creation
   */
  addTag(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;
    this.currentTags.push(value.toLowerCase());
    if (input) {
      input.value = '';
    }

    this.pageForm.get('tags').setValue('');
  }

  /**
   * Removes a tah
   * @param tagToRemove The label of the tag to remove
   */
  removeTag(tagToRemove: string) {
    this.currentTags = this.currentTags.filter(tag => tag !== tagToRemove);
  }

  /**
   * Populate the form with existing recipient's data
   * @param recipientId Recipient id
   */
  private populateForm(recipientId: number) {
    this.isLoading$.next(true);
    this.recipientContactsDataService.getById(recipientId).pipe(
      take(1),
      finalize(() => {
        this.isLoading$.next(false);
      })
    ).subscribe(recipient => {
      this.pageForm.get('firstName').setValue(recipient.firstName);
      this.pageForm.get('lastName').setValue(recipient.lastName);
      this.pageForm.get('routingNumber').setValue(recipient.bankAccount.usAbaRoutingNumber);
      this.pageForm.get('accountNumber').setValue(recipient.bankAccount.accountNumber);
      this.pageForm.get('accountType').setValue(recipient.bankAccount.paymentType);
      this.pageForm.get('memo').setValue(recipient.memo);
      this.pageForm.get('checkingOrSavingsType').setValue(recipient.bankAccount?.checkingOrSavingsType);

      recipient.contactTags.forEach(tag => {

        // @ts-ignore
        this.addTag({input: this.pageForm.get('tags'), value: tag});
      });

      this.recipientLocation = recipient.contactLocation;
    });
  }

  isWirePaymentType() {
    return this.pageForm.get('accountType')?.value === PaymentType.Wire;
  }

  addressFormReady(event) {
    if (!!this.recipientLocation) {
      this.pageForm.get('address').patchValue(this.recipientLocation);
    }
  }
}
