import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
} from "@angular/core";
import { SortEvent } from "primeng/api";
import { Table } from "primeng/table";
import { ContactsEmailTrackingService } from "./contacts-email-tracking.service";
import { filter, map, shareReplay, switchMap, take, tap } from "rxjs/operators";
import { InvestorCommunicationDialogComponent } from "../../shared/investor-communication/investor-communication-dialog/investor-communication-dialog.component";
import { InvestorCommunicationResultSnackbarComponent } from "../../shared/investor-communication/investor-communication-result-snackbar/investor-communication-result-snackbar.component";
import { DialogService } from "src/app/services/dialog.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { PermissionService } from "src/app/permission/permission.service";
import { AlertDialogParams } from "src/app/shared/components/alert-dialog/alert-dialog.component";
import PermissionLevel from "src/app/permission/enums/permissionLevel.enum";

@Component({
  selector: "terra-contacts-email-tracking",
  templateUrl: "./contacts-email-tracking.component.html",
  styleUrls: ["./contacts-email-tracking.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactsEmailTrackingComponent implements OnInit {
  @ViewChild("pTable") pTable: Table;

  constructor(
    private contactsEmailService: ContactsEmailTrackingService,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private permissionService: PermissionService
  ) {}
  filteringText: string;
  Date = Date;
  emails$ = this.contactsEmailService.getAll().pipe(
    map((emails) =>
      emails.map((email) => ({
        datetime: email.createTimeStamp,
        contactName: email.recipientEmail,
        emailSubject: email.subject,
        holding: email.extraData || "-",
        userSender: `${email.originUser.firstName} ${email.originUser.lastName}`,
      }))
    ),
    shareReplay(1)
  );
  cols = [
    {
      displayName: "Action/Subject",
      name: "emailSubject",
      type: String,
      shouldSort: false,
    },
    // {
    //   displayName: "Holding",
    //   name: "holding",
    //   type: String,
    //   shouldSort: true,
    // },
    {
      displayName: "Recipient",
      name: "contactName",
      type: String,
      shouldSort: true,
    },
    {
      displayName: "Date Sent",
      name: "datetime",
      type: Date,
      shouldSort: true,
    },
  ];
  ngOnInit(): void {}

  tableSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
      } else if (typeof value1 === "string" && typeof value2 === "string") {
        result = value1.localeCompare(value2);
      } else {
        result = value1 - value2;
      }

      return event.order * result;
    });
  }
  clearSearch(searchInput: HTMLInputElement = null) {
    if (searchInput) {
      searchInput.value = null;
    }
    this.pTable.filterGlobal(null, "");
  }
  filterChanged(value: any, matchMode: string) {
    if (!value) {
      this.clearSearch();
    } else if (value) {
      this.pTable.filterGlobal(value, matchMode);
    }
  }
  onRowSelect(event) {
    console.log(event);
  }

  openInvestorCommunicationDialog() {
    this.permissionService
      .checkPermission(PermissionLevel.Editor)
      .pipe(
        take(1),
        switchMap(([result, _]) =>
          result
            ? this.dialogService
                .customDialog(InvestorCommunicationDialogComponent, {
                  contentTemplateId: 2,
                  dialogTitle: "New Email",
                })
                .afterClosed()
                .pipe(
                  take(1),
                  filter((result) => result),
                  tap((result) =>
                    this.snackbarService.showSnackbarFromComponent(
                      InvestorCommunicationResultSnackbarComponent,
                      result,
                      2,
                      "investor-communication-message-snackbar"
                    )
                  )
                )
            : this.dialogService
                .alertDialog(
                  new AlertDialogParams(
                    "Not Permitted",
                    "You are a read-only user so you cannot perform this action!"
                  )
                )
                .afterClosed()
        )
      )
      .subscribe();
  }
}
