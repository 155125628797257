import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { filter, switchMap } from 'rxjs/operators';

import { InvestorContactReferrerDialogComponent, ContactReferrerEditDialogContext } from '../investor-contact-dialog/investor-contact-referrer-dialog/investor-contact-referrer-dialog.component';
import { ContactReferrersDialogService } from './contact-referrers-dialog.service';
import { ContactReferrersDialogQuery } from './state/contact-referrers-dialog.query';
import { ContactReferrersDialogStore } from './state/contact-referrers-dialog.store';
import { ContactReferrerReqRes } from 'src/app/shared/models/ContactReferrerReqRes.model';
import { DialogService } from 'src/app/services/dialog.service';
import { ConfirmDialogParams } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import {BaseResponseDto} from '../../../../shared/models/BaseResponseDto.model';
import {UtilsService} from '../../../../services/utils.service';
import { PermissionService } from 'src/app/permission/permission.service';

@Component({
  selector: 'terra-contact-referrers-dialog',
  templateUrl: './contact-referrers-dialog.component.html',
  styleUrls: ['./contact-referrers-dialog.component.scss'],
  providers: [ContactReferrersDialogService, ContactReferrersDialogQuery, ContactReferrersDialogStore]
})
export class ContactReferrersDialogComponent extends OnDestroyMixin implements OnInit {
  allowInvestorName$ = this.permissionService.allowInvestorName$;
  contactReferrers$ = this.query.selectAll();
  count$ = this.query.selectCount();
  loading$ = this.query.selectLoading();

  displayedColumns: string[] = ['name', 'action'];

  constructor(
    private dialogRef: MatDialogRef<ContactReferrersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private query: ContactReferrersDialogQuery,
    private contactReferrersDialogService: ContactReferrersDialogService,
    private dialogService: DialogService,
    private permissionService: PermissionService,
    private utilsService: UtilsService
  ) {
    super();
    dialogRef.addPanelClass('terra-contact-referrers-dialog');
  }

  ngOnInit(): void {
  }

  addContactReferrerDialog() {
    this.dialog.open(InvestorContactReferrerDialogComponent).afterClosed()
      .pipe(
        untilComponentDestroyed(this),
        filter(referrer => referrer),
        switchMap(referrer => this.contactReferrersDialogService.addContactReferrer(referrer))
      ).subscribe(
      () => {},
      error => {
        if (error instanceof BaseResponseDto) {
          this.utilsService.alertErrorMessage(error);
        }
      }
    );
  }

  edit(contactReferrer: ContactReferrerReqRes) {
    const dialogConfig = new MatDialogConfig<ContactReferrerEditDialogContext>();
    const data = new ContactReferrerEditDialogContext();
    data.contactReferrer = contactReferrer;
    dialogConfig.data = data;

    this.dialog.open(InvestorContactReferrerDialogComponent, dialogConfig).afterClosed().pipe(
      untilComponentDestroyed(this),
      filter(referrer => referrer),
      switchMap(referrer => this.contactReferrersDialogService.updateContactReferrer(referrer))
    ).subscribe(
      () => {},
      error => {
        if (error instanceof BaseResponseDto) {
          this.utilsService.alertErrorMessage(error);
        }
      }
    );
  }

  delete(contactReferrer: ContactReferrerReqRes) {
    const confirmationText = contactReferrer.attachedContactsCount === 0 ?
      `<p>Are you sure you want to delete this contact referrer?</p>` :
      `<p>The contact referrer you are about to delete is attached to ${contactReferrer.attachedContactsCount} contacts.</p>
      <p>Are you sure you want to delete it?</p>`;

    this.dialogService.confirmDialog(new ConfirmDialogParams('Delete Contact Referrer', confirmationText)).afterClosed()
      .pipe(
        untilComponentDestroyed(this),
        filter(isConfirmed => isConfirmed),
        switchMap(() => this.contactReferrersDialogService.deleteContactReferrer(contactReferrer.id)))
      .subscribe(
        () => {},
        error => {
          if (error instanceof BaseResponseDto) {
            this.utilsService.alertErrorMessage(error);
          }
        }
      );
  }
}
