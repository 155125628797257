<div
  class="email-tracking-container"
  *ngIf="emails$ | async as emails; else loader"
>
  <p-table
    #pTable
    [value]="emails"
    [columns]="cols"
    [showCurrentPageReport]="true"
    [alwaysShowPaginator]="true"
    [showFirstLastIcon]="false"
    [tableStyle]="{ 'min-width': '50rem' }"
    [styleClass]="'p-datatable-sm'"
    [rowHover]="true"
    [globalFilterFields]="['contactName', 'emailSubject']"
    responsiveLayout="scroll"
    *ngIf="emails"
    [scrollable]="true"
    scrollHeight="flex"
    (sortFunction)="tableSort($event)"
    [customSort]="true"
    [paginator]="true"
    [rows]="10"
    [totalRecords]="emails.length"
    [rowsPerPageOptions]="[10, 25, 50, 100]"
    currentPageReportTemplate="{first} - {last} of {totalRecords}"
  >
    <ng-template pTemplate="caption">
      <div class="flex align-items-center justify-content-between">
        <div class="flex">
          <terra-search-field
            placeholder="Search by email or subject"
            (filterChange)="filterChanged($event, 'contains')"
            (clearSearch)="clearSearch()"
            [(filter)]="filteringText"
          ></terra-search-field>
        </div>
        <terra-buttons
          [requireContactsVisibility]="true"
          [popupOnPermission]="true"
          type="secondary"
          label="New Email"
          icon="mail_outline"
          (onClick)="openInvestorCommunicationDialog()"
        ></terra-buttons>
      </div>
    </ng-template>
    <ng-template pTemplate="header" #headerTemplate>
      <tr class="text-xs">
        <th class="w-1rem"></th>
        <th
          [pSortableColumn]="col.name"
          [pSortableColumnDisabled]="!col.shouldSort"
          *ngFor="let col of cols"
        >
          {{ col.displayName }}
          <p-sortIcon *ngIf="col.shouldSort" [field]="col.name"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-columns="columns" let-rowData>
      <tr>
        <td>
          <span class="email-icon-container"
            ><i class="pi pi-envelope"></i
          ></span>
        </td>
        <td *ngFor="let col of columns">
          {{
            col.type === Date
              ? (rowData[col.name] | date : "yyyy/MM/dd HH:mm:ss")
              : rowData[col.name]
          }}
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <ng-container *ngIf="emails.length; else noEmails">
        <tr class="c-empty-state">
          <td class="text-center" colspan="9">
            <img
              src="/assets/images/plg/no-results-con.svg"
              alt="no emails here yet"
            />
            <p class="text-center font-medium text-lg main-hint">
              No matching results
            </p>
            <p class="text-center secondary-hint">
              Please adjust your search or filter and try again.
            </p>
          </td>
        </tr>
      </ng-container>
      <ng-template #noEmails>
        <tr class="c-empty-state">
          <td class="text-center" colspan="9">
            <img
              src="/assets/images/plg/empty-icon.svg"
              alt="no emails here yet"
            />
            <p class="text-center font-medium text-lg secondary-hint">
              No emails here yet
            </p>
          </td>
        </tr>
      </ng-template>
    </ng-template>
  </p-table>
</div>

<ng-template #loader>
  <terra-loader></terra-loader>
</ng-template>
