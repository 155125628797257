import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CovercySharedComponentsModule } from 'src/app/shared/CovercySharedComponents.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from 'src/app/shared/material.module';
import { GpSharedModule } from 'src/app/shared/gp-shared.module';
import { AccreditationSendRequestDialogComponent } from './accreditation-send-request-dialog/accreditation-send-request-dialog.component';
import { AccreditationComponent } from './accreditation.component';
import { AccreditationService } from './accreditation.service';


@NgModule({
  declarations: [
    AccreditationComponent,
    AccreditationSendRequestDialogComponent
  ],
  exports:[
    AccreditationComponent,
    AccreditationSendRequestDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    CovercySharedComponentsModule,
    GpSharedModule
  ],
  providers:[
    AccreditationService
  ]
})
export class AccreditationModule { }
