import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';

import { ContactReferrersDialogStore } from './state/contact-referrers-dialog.store';
import { ContactReferrerDataService } from 'src/app/services/gp/contact-referrer-data.service';
import { SearchOptionsRequest } from 'src/app/shared/models/SearchOptionsRequest.model';
import { ContactReferrerReqRes } from 'src/app/shared/models/ContactReferrerReqRes.model';
@Injectable()
export class ContactReferrersDialogService extends OnDestroyMixin {

  constructor(
    private store: ContactReferrersDialogStore,
    private contactReferrerDataService: ContactReferrerDataService
  ) {
    super();
    this.getAllContactReferrers().pipe(
      untilComponentDestroyed(this)
    )
      .subscribe(contactReferrers => {
        store.set(contactReferrers);
      });
  }

  addContactReferrer(contactReferrer: ContactReferrerReqRes) {
    return this.contactReferrerDataService.create(contactReferrer).pipe(
      tap(createdItem => {
        this.store.add(createdItem);
      }));
  }

  updateContactReferrer(contactReferrer: ContactReferrerReqRes) {
    return this.contactReferrerDataService.update(contactReferrer.id, contactReferrer).pipe(
      tap(updatedItem => {
        this.store.upsert(contactReferrer.id, updatedItem);
      }));
  }

  deleteContactReferrer(id: number) {
    return this.contactReferrerDataService.delete(id).pipe(
      tap(() => this.store.remove(id))
    );
  }

  private getAllContactReferrers() {
    const getAllSearchOptions = new SearchOptionsRequest();
    getAllSearchOptions.pageSize = 1000;
    return this.contactReferrerDataService.getList(getAllSearchOptions).pipe(map(response => response.rows));
  }
}
