<h1 class="page-header">Contacts</h1>

<nav class="tabs-navigation" mat-tab-nav-bar [tabPanel]="tabPanel" mat-stretch-tabs="false" mat-align-tabs="start">
  <!-- Investor -->
  <a
    mat-tab-link
    routerLink="investors"
    routerLinkActive
    #rla2="routerLinkActive"
    [active]="rla2.isActive"
  >
    INVESTORS
  </a>

  <!-- Recipients -->
  <a
    mat-tab-link
    routerLink="recipients"
    routerLinkActive
    #rla3="routerLinkActive"
    [active]="rla3.isActive"
    *ngIf="userHasCreBanking$ | async"
  >
    RECIPIENTS
  </a>

  <!-- Recipients -->
  <a
    *ngIf="userHasEmailTracking$ | async"
    mat-tab-link
    routerLink="emailtracking"
    routerLinkActive
    #rla4="routerLinkActive"
    [active]="rla4.isActive"
  >
    {{ "Email Tracking" | uppercase }}
  </a>
</nav>

<!-- Tabs content  -->
<mat-tab-nav-panel #tabPanel>
  <router-outlet />
</mat-tab-nav-panel>
