<form [formGroup]="pageForm" (keydown.enter)="$event.preventDefault()">
  <div class="actions">
    <div class="filter-bar">
      <button
        class="create-new-contact-button tall-button"
        type="button"
        mat-flat-button
        color="primary"
        (click)="showContactModal(0)"
      >
        <mat-icon class="notranslate">add</mat-icon> Add a New Contact
      </button>

      <mat-form-field
        appearance="outline"
        class="text-filter-wrapper remove-error-place"
      >
        <input
          matInput
          formControlName="filter"
          class="lo_sensitive"
          placeholder="Advance Search"
        />
      </mat-form-field>

      <div class="tags-filter-wrapper" *ngIf="allTags$ | async as allTags">
        <mat-form-field
          appearance="outline"
          *ngIf="allTags"
          class="remove-error-place"
        >
          <mat-select
            matInput
            placeholder="Filter by tags"
            formControlName="multiselectTags"
            multiple
          >
            <mat-option *ngFor="let tag of allTags" [value]="tag.key">{{
              tag.value
            }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-chip-set class="terra-chip"> <!-- use grid if interactive-->
          <mat-chip *ngFor="let tag of selectedTags$ | async">
            {{ tag.value }}
          </mat-chip>
        </mat-chip-set>
      </div>

      <div class="contact-referrer-filter">
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="contactReferrerId"
            placeholder="Contact referrer"
          >
            <mat-option [value]="null">None</mat-option>
            <mat-option
              *ngFor="let contactReferrer of allContactReferrers$ | async"
              [value]="contactReferrer.id"
            >
              <ng-container *ngIf="allowInvestorName$ | async">
                {{
                  contactReferrer.name + " - " + contactReferrer.id
                    | truncate : 40 : true
                }}
              </ng-container>
              <ng-container *ngIf="!(allowInvestorName$ | async)">
                {{ contactReferrer.id }}
              </ng-container>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <button
        type="button"
        mat-button
        aria-label="Close"
        *ngIf="showClearFilter$ | async"
        (click)="clearForm()"
        class="tall-button"
      >
        Clear all
      </button>
    </div>

    <div class="more-actions">
      <button
        mat-button
        [matMenuTriggerFor]="menu"
        class="btn-actions tall-button flow-contacts-actions"
        mat-stroked-button
      >
        <mat-icon>more_vert</mat-icon>
        Actions
      </button>

      <mat-menu #menu="matMenu">
        <!-- Send Email button -->
        <button
          mat-menu-item
          id="send-email-button"
          (click)="openInvestorCommunicationDialog()"
        >
          <mat-icon svgIcon="email" class="notranslate"></mat-icon>
          Send Email
        </button>
        <!-- Export button -->
        <button mat-menu-item id="export-button" (click)="export()">
          <mat-icon svgIcon="terra-export" class="notranslate"></mat-icon>
          Export
        </button>
        <!-- Import button -->
        <button
          mat-menu-item
          id="import-button"
          class="flow-contacts-actions-import"
          (click)="import()"
        >
          <mat-icon svgIcon="terra-import" class="notranslate"></mat-icon>
          Import
        </button>

        <!-- Manage contact referrers -->
        <button
          mat-menu-item
          id="import-button"
          (click)="manageContactReferrers()"
        >
          <mat-icon class="notranslate">person_pin</mat-icon>
          Manage contact referrers
        </button>
      </mat-menu>
    </div>
  </div>
</form>

<terra-investor-contacts-display-table
  (editRow)="showContactModal($event)"
  (deleteRow)="showDeleteModal($event)"
  (viewAsInvestor)="viewAsInvestor($event)"
  (selectedRows)="selectedContacts = $event"
  [isLoadingViewAsInvestor$]="isLoadingViewAsInvestor$"
/>
