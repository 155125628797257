import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  HostBinding,
} from "@angular/core";
import { Title } from "@angular/platform-browser";

import { TerraUtils } from "src/app/shared/TerraUtils";
import { UserService } from "../../services/shared/user.service";
import FeatureFlags from "../../account/my-account/model/FeatureFlags.enum";

@Component({
  selector: "terra-contacts",
  templateUrl: "./contacts.component.html",
  styleUrls: ["./contacts.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactsComponent implements OnInit {
  @HostBinding("attr.class") hostClasses = "container";

  userHasCreBanking$ = this.userService.userHasFeatureFlag(
    FeatureFlags.CreBanking
  );
  userHasEmailTracking$ = this.userService.userHasFeatureFlag(
    FeatureFlags.EmailTracking
  );
  constructor(private titleService: Title, private userService: UserService) {}

  ngOnInit() {
    this.titleService.setTitle(
      "Contacts" + TerraUtils.consts.GpPageTitleSuffix
    );
  }
}
