import {Injectable} from '@angular/core';
import {Observable, merge, Subject, BehaviorSubject} from 'rxjs';
import {scan, map, shareReplay, distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';

import {InvestingEntitySearchOptionsRequest} from './InvstingEntitySearchOptionsRequest.model';
import {AppService, AppQuery} from 'src/app/state';

@Injectable()
export class InvestingEntityTableService extends OnDestroyMixin {

  private _refreshData$ = new Subject<void>();
  refreshData$ = this._refreshData$.asObservable();

  private contactId$ = new BehaviorSubject<number>(null);

  // The fundraising ID is not a filter that the user can control, and it is set even before connecting to the data source,
  // so we want it to store the current value and not just emit new values when the user changes a filter:
  private fundraisingId$ = new BehaviorSubject<number>(0);
  // The rest are filters that the user can control:
  private pageSize$ = new Subject<number>();
  private pageNumber$ = new Subject<number>();
  private tagsFilter$ = new Subject<number[]>();
  private contactReferrerIdFilter$ = new Subject<number>();
  private textFilter$ = new Subject<string>();
  private holdingId$ = new Subject<number>();
  private distributionId$ = new Subject<number>();

  private excludeAgentContactInvestingEntities$ = new BehaviorSubject<boolean>(false);

  private holdingIdSource$ = this.holdingId$.pipe(map(holdingId => ({holdingId}) as InvestingEntitySearchOptionsRequest));
  private distributionIdSource$ = this.distributionId$.pipe(map(distributionId => ({distributionId}) as InvestingEntitySearchOptionsRequest));
  private excludeAgentContactInvestingEntitiesSource$ = this.excludeAgentContactInvestingEntities$.pipe(distinctUntilChanged(), map(excludeAgentContacts => ({excludeAgentContacts}) as InvestingEntitySearchOptionsRequest));
  private contactIdSource$ = this.contactId$.pipe(map(contactId => ({contactId}) as InvestingEntitySearchOptionsRequest));
  private fundraisingIdSource$ = this.fundraisingId$.pipe(map(fundraisingId => ({fundraisingId}) as InvestingEntitySearchOptionsRequest));
  private pageNumberSource$ = this.pageNumber$.pipe(map(pageNumber => ({pageNumber}) as InvestingEntitySearchOptionsRequest));
  private pageSizeSource$ = this.pageSize$.pipe(map(pageSize => ({pageSize}) as InvestingEntitySearchOptionsRequest));
  private textFilterSource$ = this.textFilter$.pipe(map(filter => filter.trim()), distinctUntilChanged(), map(filter => ({filter}) as InvestingEntitySearchOptionsRequest));
  private contactReferrerFilterSource$ = this.contactReferrerIdFilter$.pipe(map(contactReferrerId => ({contactReferrerId: contactReferrerId}) as InvestingEntitySearchOptionsRequest));
  private tagsSource$: Observable<InvestingEntitySearchOptionsRequest> = this.tagsFilter$.pipe(map(tags => ({tags: tags || []}) as InvestingEntitySearchOptionsRequest));
  private uiStateSource$: Observable<InvestingEntitySearchOptionsRequest> = this.appQuery.gpUiPrefs.pipe(
    map(state => state.contacts.sort),
    map(sort => ({orderBy: sort.orderBy, sortOrder: sort.direction}) as InvestingEntitySearchOptionsRequest));

  // Merged source of search options
  private searchOptionsSources$: Observable<InvestingEntitySearchOptionsRequest> = merge(
    this.holdingIdSource$,
    this.distributionIdSource$,
    this.uiStateSource$,
    this.excludeAgentContactInvestingEntitiesSource$,
    this.contactIdSource$,
    this.fundraisingIdSource$,
    this.textFilterSource$,
    this.contactReferrerFilterSource$,
    this.tagsSource$,
    this.pageNumberSource$,
    this.pageSizeSource$);

  // Search options state
  searchOptions$: Observable<InvestingEntitySearchOptionsRequest> = this.searchOptionsSources$
    .pipe(
      untilComponentDestroyed(this),
      scan((acc, value) => {
        // Whenever somehting changes, unless it's the pagenumber, return to the first page
        
        const pageNumber = value.pageNumber === undefined ? 0 : value.pageNumber;
        return {...acc, ...value, pageNumber} as InvestingEntitySearchOptionsRequest;
      }, new InvestingEntitySearchOptionsRequest()),
      debounceTime(10),
      shareReplay(1)
    );

  constructor(private appService: AppService, private appQuery: AppQuery) {
    super();
  }

  refresh() {
    this._refreshData$.next();
  }

  sort(orderBy: string, sortOrder: string) {
    this.appService.updateContactsSortState({orderBy, direction: sortOrder === 'desc' ? 'desc' : 'asc'});
  }

  updateFilter(term: string) {
    this.textFilter$.next(term);
  }

  updateTagsFilter(tags: number[]) {
    this.tagsFilter$.next(tags);
  }

  updateContactReferrerFilter(contactReferrerId: number) {
    this.contactReferrerIdFilter$.next(contactReferrerId);
  }

  updatePageSize(pageSize: number) {
    this.pageSize$.next(pageSize);
  }

  updatePageNumber(page: number) {
    this.pageNumber$.next(page);
  }

  updateFundraisingId(fundraisingId: number) {
    this.fundraisingId$.next(fundraisingId);
  }

  updateContactId(contactId: number) {
    this.contactId$.next(contactId);
  }

  excludeAgentContacts() {
    this.excludeAgentContactInvestingEntities$.next(true);
  }

  updateHoldingId(holdingId: number) {
    this.holdingId$.next(holdingId);
  }

  updateDistributionId(distributionId: number) {
    this.distributionId$.next(distributionId);

  }


}
