import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserService } from '../services/shared/user.service';
import { RoutingService } from '../services/routing.service';
import { AppSettingsService } from '../services/app-settings.service';
import FeatureFlags from '../account/my-account/model/FeatureFlags.enum';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagGuard  {

  constructor(
    private userService: UserService,
    private appSettingsService: AppSettingsService,
    private routingService: RoutingService,
    private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    boolean |
    UrlTree |
    Observable<boolean | UrlTree> |
    Promise<boolean | UrlTree> {
    return this.userService.userHasFeatureFlag(next.data.featureFlag).pipe(
      map(hasFeature => hasFeature ? true : this.router.createUrlTree(['not-found'])));
  }
}
