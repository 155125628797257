import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';

import { ClientBankAccountReqRes } from 'src/app/dashboard/models/bankAccount.model';
import { GpBankAccountDataService } from 'src/app/services/gp/gp-bank-account-data.service';
import { fadeInOut, enterAnimation } from 'src/app/shared/animations';
import { TerraUtils } from 'src/app/shared/TerraUtils';
import { BeneficiaryEntityType } from 'src/app/shared/enums/BeneficiaryEntityType.enum';
import { CountryModel } from 'src/app/shared/models/CountryModel';
import { ResourceService } from 'src/app/services/resource.service';
import { DialogWithFormBase } from 'src/app/shared/types/DialogWithFormBase';
import { DialogService } from 'src/app/services/dialog.service';
import { AlertDialogParams } from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import { InvestingEntityReqRes } from 'src/app/dashboard/models/InvestingEntityReqRes.model';
import { CurrencyModel } from 'src/app/shared/models/CurrencyModel';
import { EditBankAccountContext } from './../edit-bank-account/EditBankAccountContext.model';
import { AddressFormSettings } from 'src/app/shared/components/address-form/address-form.settings';

@Component({
  selector: 'terra-view-bank-account',
  templateUrl: './view-bank-account.component.html',
  styleUrls: ['./view-bank-account.component.scss'],
  animations: [fadeInOut, enterAnimation]
})
export class ViewBankAccountComponent extends DialogWithFormBase implements OnInit {
  BeneficiaryEntityType = BeneficiaryEntityType;
  pageForm: UntypedFormGroup;
  isSubmitted = false;
  showInternalLoader = false;

  bankAccountDetails: ClientBankAccountReqRes;
  bankAccountId = 0;
  isGeneralServerError = false;
  generalServerErrorMessage = TerraUtils.consts.messages.GENERAL_SUBMIT_ERROR_WITH_LINK;
  allCountries = this.resourceService.allCountries;

  isIgnoreReadOnlyBeneficiaryEntityType = false;
  isIgnoreReadOnlyBeneficiaryTin = false;
  isAnOrganizationAccountDefault = true;

  addressFormSettings = new AddressFormSettings();

  enablePreferredCurrency = false;
  // isRecomendedCurrencySelected$ = new BehaviorSubject(false);
  investingEntity: InvestingEntityReqRes = null;

  constructor(
    private fb: UntypedFormBuilder,
    private gpBankAccountDataService: GpBankAccountDataService,
    private resourceService: ResourceService,
    @Inject(MAT_DIALOG_DATA) private data: EditBankAccountContext,
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<ViewBankAccountComponent>
  ) {
    super();
    dialogRef.addPanelClass('view-bank-account-dialog');
  }

  ngOnInit() {
    this.bankAccountDetails = new ClientBankAccountReqRes();
    this.bankAccountId = 0;

    if (this.data) {

      if (this.data.id) {
        this.bankAccountId = this.data.id;
      }
      if (this.data.enablePreferredCurrency) {
        this.enablePreferredCurrency = this.data.enablePreferredCurrency;
      }
      if (this.data.investingEntity) {
        this.investingEntity = this.data.investingEntity;
      }
      if (this.data.isAnOrganizationAccountDefault !== null) {
        this.isAnOrganizationAccountDefault = this.data.isAnOrganizationAccountDefault;
      }
    }

    this.generateForm();
    this.subscribeToIsOrganizationChange();

    if (this.bankAccountId > 0) {
      this.populateForm(this.bankAccountId);
    }
  }

  generateForm() {

    this.pageForm = this.fb.group({
      nickname: [null, Validators.required],
      holderFullName: [null, Validators.required],
      isAnOrganizationAccount: [this.isAnOrganizationAccountDefault, Validators.required],
      beneficiaryEntityType: [null, this.isAnOrganizationAccountDefault ? Validators.required : null],
      beneficiaryTin: [null, this.isAnOrganizationAccountDefault ? Validators.required : null],
      bankDetails: {},
      holderLocationDetails: {}
    });

    if (this.investingEntity) {
      this.pageForm.get('beneficiaryEntityType').setValue(this.investingEntity.investingEntityType);
      this.pageForm.get('beneficiaryTin').setValue(this.investingEntity.tin);
    }
  }

  populateForm(bankAccountId: number) {
    this.showInternalLoader = true;

    this.gpBankAccountDataService
      .getById(bankAccountId)
      .pipe(
        finalize(() => {
          this.showInternalLoader = false;
        })
      )
      .subscribe(
        bankAccount => {
          this.bankAccountDetails = bankAccount;

          this.pageForm.patchValue({
            nickname: bankAccount.nickname,
            holderFullName: bankAccount.holderFullName,
            isAnOrganizationAccount: bankAccount.isAnOrganizationAccount,
            beneficiaryEntityType: bankAccount.beneficiaryEntityType !== 0 ? bankAccount.beneficiaryEntityType : null,
            beneficiaryTin: bankAccount.beneficiaryTin
          });

          // Disable fields/forms
          this.pageForm.disable();
          this.pageForm.get('bankDetails').disable();
          this.pageForm.markAllAsTouched();
        },
        err => {
          console.log('Error', err);
        }
      );
  }

  isCollectWitholdingTaxData(): boolean {
    return this.pageForm.get('isAnOrganizationAccount').value;
  }

  subscribeToIsOrganizationChange() {
    this.pageForm.controls.isAnOrganizationAccount.valueChanges.pipe(untilComponentDestroyed(this)).subscribe(isOrganization => {
      if (this.isCollectWitholdingTaxData()) {
        this.pageForm.controls.beneficiaryTin.setValidators([Validators.required]);
        this.pageForm.controls.beneficiaryEntityType.setValidators([Validators.required]);

      } else {
        this.pageForm.controls.beneficiaryTin.setValidators([]);
        this.pageForm.controls.beneficiaryTin.setValue(null);
        this.pageForm.controls.beneficiaryTin.markAsUntouched();
        this.pageForm.controls.beneficiaryEntityType.setValidators([]);
        this.pageForm.controls.beneficiaryEntityType.setValue(null);
        this.pageForm.controls.beneficiaryEntityType.markAsUntouched();
      }
      this.pageForm.controls.beneficiaryTin.updateValueAndValidity();
      this.pageForm.controls.beneficiaryEntityType.updateValueAndValidity();
    });
  }

  getAddressCountry(): CountryModel {
    const locationDetailsForm = this.pageForm.get('holderLocationDetails') as UntypedFormGroup;
    if (!locationDetailsForm.get('countryId') || !locationDetailsForm.get('countryId').value) {
      return null;
    }
    const addressCountryId = this.pageForm.get(['holderLocationDetails', 'countryId']).value;
    return this.allCountries ? this.allCountries.find(c => c.id === addressCountryId) : null;
  }

  currencyLearnMore(isRecommdendedCurrencySelected: boolean, recommendedCurrency: CurrencyModel, selectedCurrency: CurrencyModel) {
    let longText = '';
    if (!isRecommdendedCurrencySelected) {
      longText = `
      <p>Choosing to distribute funds to your investors in the local currency of their country usually gives the best value for money. It also means Covercy can use a local (and faster) payment in that country which means funds arrive sooner.</p>
      <p>In case you choose a currency which is not the local currency in that country, Covercy will use SWIFT to send your funds. This means that your transfer will take longer and be more expensive, as investors' banks may also deduct their handling fees when receiving the funds, or once your investors choose to convert to their local currency.</p>
      <p><strong>We highly recommend you to choose ${recommendedCurrency.iso} instead of ${selectedCurrency.iso}</strong></p>
      `;
    } else {
      longText = `
      <p>Covercy offers the best exchange rates and guarantees the exact amount received in ${recommendedCurrency.iso} in the destination bank account.</p>
      <p>With Covercy, there's no room for hidden bank surcharges or additional charges that you don't see here.</p>
      <p>By delivering funds to your investors in their country's local currency, you save them the hassle and fees of converting the funds themselves.</p>`
        // Compare Covercy exchange rates and fees with some of the biggest money transfer services around.
        ;
    }
    this.dialogService.alertDialog({
      title: 'Recommended currency for distribution',
      description: longText
    } as AlertDialogParams);
  }
}
