import { Injectable } from "@angular/core";
import { Observable, catchError, of, switchMap, throwError } from "rxjs";
import { HttpService } from "src/app/services/http.service";
import { InvestorContactAssociatedEmailMessage } from "./investor-contact-associated-email-message";
import { EmailMessageModel } from "./email-message.model";

@Injectable({
  providedIn: "root",
})
export class ContactsEmailTrackingService {
  constructor(protected http: HttpService) {}
  protected readonly baseEndpoint$ = of("emailmessages/");

  public getAll(): Observable<EmailMessageModel[]> {
    return this.baseEndpoint$.pipe(
      switchMap((endpoint) =>
        this.http.getTyped<EmailMessageModel[]>(endpoint)
      ),
      catchError((err) => {
        return throwError(() => err);
      })
    );
  }
}
