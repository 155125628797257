<terra-dialog-header>
  <h1>Contact Referrers</h1>
</terra-dialog-header>

<ng-template #loader>
  <terra-loader class="loader"> </terra-loader>
</ng-template>

<mat-dialog-content *ngIf="!(loading$ | async) else loader">
  <div class="top-actions">
    <!-- Add investing entity -->
    <button type="button" mat-stroked-button color="primary" (click)="addContactReferrerDialog()" class="add-new covercy-wired-button">
      <mat-icon class="open_icon notranslate">add</mat-icon>
      Add Contact Referrer
    </button>
  </div>

  <ng-template #emptyState>
    <div class="empty-state">
      <p class="empty-state-message">No contact referrers yet. Click 'Add Contact Referrer' to add a new one.</p>
    </div>
  </ng-template>

  <ng-container *ngIf="(count$ | async) > 0 else emptyState">
    <table mat-table [dataSource]="contactReferrers$ | async" class="cov-basic-table gray-theme mat-elevation-z0">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="name-col-header"> Name </th>
        <td mat-cell *matCellDef="let element" class="lo_sensitive"> 
          <ng-container *ngIf="allowInvestorName$ | async">
            {{element.name}} - {{element.id}}
          </ng-container>
          <ng-container *ngIf="!(allowInvestorName$ | async)">
            {{element.id}}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="actions-col-header"></th>
        <td mat-cell *matCellDef="let element" class="value-actions">
          <div class="flex flex-row">
          <!-- Edit -->
          <button type="button" mat-icon-button class="edit-button" (click)="edit(element); $event.stopPropagation();" disableRipple
            matTooltip="Edit" matTooltipClass="terra-tooltip-compact">
            <mat-icon class="edit-icon notranslate">edit</mat-icon>
          </button>
          <!-- Delete -->
          <button type="button" mat-icon-button class="delete-button" (click)="delete(element); $event.stopPropagation(); (false)"
            disableRipple matTooltip="Delete" matTooltipClass="terra-tooltip-compact">
            <mat-icon class="delete-icon notranslate">delete</mat-icon>
          </button>
        </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-flat-button color="primary" class="close-button" [mat-dialog-close]="undefined">Close</button>
</mat-dialog-actions>
