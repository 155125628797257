<ng-container *ngIf="{
  searchOptions: searchOptions$ | async,
  pageRowsCount: pageRowsCount$ | async,
  totalRowsCount : totalRowsCount$ | async} as data">

  <!-- Summary + page size selector -->
  <div class="results-pane-title" *ngIf="data.totalRowsCount > itemsPerPageOptions[0]">
    <div>Showing {{ data.pageRowsCount }} of {{ data.totalRowsCount }} contacts</div>
    <mat-form-field appearance="outline" class="remove-error-place">
      <mat-select [formControl]="perPageControl">
        <mat-option *ngFor="let amountPerPage of itemsPerPageOptions" [value]="amountPerPage">{{amountPerPage}} per page
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- select all contacts -->
  <div *ngIf="(isAllSelectedInPage$ | async) && !selectAllMessages && data.pageRowsCount < data.totalRowsCount" class="select-all-message">
    All {{data.pageRowsCount}} contact on this page are selected. <span class="select-all-btn" (click)="toggleAllMessages(true)">Click here to select all {{data.totalRowsCount}} contacts</span>
  </div>
  <div *ngIf="(isAllSelectedInPage$ | async) && selectAllMessages && data.pageRowsCount < data.totalRowsCount" class="select-all-message">
    All {{data.totalRowsCount}} contact are selected. <span class="select-all-btn" (click)="toggleAllMessages(false)">Clear selection</span>
  </div>

  <!-- Data table -->
  <div class="hide-investor-name-message-sort" *ngIf="!(allowInvestorName$ | async) && data.totalRowsCount > 0">To find a specific contact by ID please use the Filter by name field above</div>
  <table mat-table [dataSource]="dataSource" [trackBy]="trackByFunction" class="gray-theme mat-elevation-z0" matSort
         matSortDisableClear *ngIf="data.searchOptions"
         (matSortChange)="sortData($event)" [matSortDirection]="data.searchOptions.sortOrder"
         [matSortActive]="data.searchOptions.orderBy">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef class="gray-theme checkbox-col-header">
         <span class="checkbox-and-more">
           <mat-checkbox (change)="$event ? masterToggle($event) : null"
                         [indeterminate]="isSomeSelectedInPage$ | async"
                         [checked]="isAllSelectedInPage$ | async"
                         [disabled]="data.pageRowsCount === 0 || (isLoading$ | async)"
                         color="primary">
               </mat-checkbox>
         </span>

        <button mat-icon-button class="header-delete-button" (click)="deleteMultiple()"
                *ngIf="deleteRow && deleteRow.observers.length > 0 && selection.selected.length > 0">
          <mat-icon class="notranslate">delete</mat-icon>
        </button>

      </th>
      <td mat-cell class="gray-theme" *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? rowCheckToggle(row) : null"
                      [checked]="isRowChecked(row)"
                      color="accent"></mat-checkbox>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="FirstName" [disabled]="!(allowInvestorName$ | async)" class="name-col-header">Name</th>
      <td mat-cell *matCellDef="let element" class="lo_sensitive">
        <ng-container *ngIf="allowInvestorName$ | async">
          {{ element.name }}
          ({{ element.contact.firstName }} {{ element.contact.lastName }})
        </ng-container>
        <ng-container *ngIf="!(allowInvestorName$ | async)">
          {{ element.nickname }}
          ({{ element.contact.nickname }})
        </ng-container>

        <span *ngIf="!element.contact.firstName || !element.contact.lastName" class="lo_sensitive">
          ({{ element.contact.email }})
        </span>
      </td>
    </ng-container>

    <!-- Email Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="Email" [disabled]="!(allowInvestorName$ | async)" class="email-col-header">Email</th>
      <td mat-cell *matCellDef="let element" class="lo_sensitive">
        {{ element.contact.email }}
      </td>
    </ng-container>

    <!-- Tags Column -->
    <ng-container matColumnDef="tags">
      <th mat-header-cell *matHeaderCellDef class="tags-col-header">Tags</th>
      <td mat-cell *matCellDef="let element" class="tags-column-content">
        <mat-chip-set class="terra-chip">
          <mat-chip *ngFor="let c of element.contact.contactTags" class="lo_sensitive">
            {{ c }}
          </mat-chip>
        </mat-chip-set>
      </td>
    </ng-container>

    <!-- Accreditation date Column -->
    <ng-container matColumnDef="accreditationDate">
      <th mat-header-cell *matHeaderCellDef class="accreditationDate-col-header">Accreditation Date</th>
      <td mat-cell *matCellDef="let element" class="lo_sensitive" [ngClass]="{'accredited': isAccredited(element), 'not-accredited': !isAccredited(element)}">
        {{ !!element.accreditation?.accreditedDate ? (investorAccreditationDate(element.accreditation.accreditedDate) | date) : 'None' }}
      </td>
    </ng-container>

    <!-- PersonalRemarks Column -->
    <ng-container matColumnDef="personalRemarks">
      <th mat-header-cell *matHeaderCellDef class="remarks-col-header"></th>
      <td mat-cell *matCellDef="let element" matTooltipClass="terra-tooltip-compact">
        <mat-icon class="notranslate material-icons" matTooltipClass="terra-tooltip tin-tooltip"
                  *ngIf="element.contact?.personalRemarks?.length" color="primary" matTooltipPosition="right"
                  matTooltip="{{element.contact?.personalRemarks}}">
          info_outline
        </mat-icon>
      </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="actions-col-header"></th>
      <td mat-cell *matCellDef="let model" class="actions" [class.hover]="showActions(model)">
        <button *ngIf="editRow && editRow.observers.length > 0" mat-icon-button class="edit-button"
                (click)="onEditRow(model.id); $event.stopPropagation(); (false)" disableRipple matTooltip="Edit"
                matTooltipClass="terra-tooltip-compact">
          <mat-icon class="edit-icon notranslate">edit</mat-icon>
        </button>
        <button *ngIf="deleteRow && deleteRow.observers.length > 0" mat-icon-button class="delete-button"
                (click)="onDeleteRow(model.id); $event.stopPropagation(); (false)" disableRipple matTooltip="Delete"
                matTooltipClass="terra-tooltip-compact">
          <mat-icon class="delete-icon notranslate">delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

    <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.selected-row]="isRowChecked(row)"
        [class.hide]="isLoading$ | async"
        (click)="rowCheckToggle(row)" (mouseover)="row.hover = true" (mouseout)="row.hover = false"
        class="value-row"></tr>
  </table>

  <!-- loader  -->
  <terra-loader *ngIf="isLoading$ | async"></terra-loader>

  <!-- Empty state -->
  <div class="empty-list-message"
       *ngIf="!(isLoading$ | async) && data.totalRowsCount == 0 && showMessageWhenListIsEmpty">
    <p>{{messageWhenListIsEmpty}}</p>
  </div>


  <mat-paginator (page)="pageChange($event)" [length]="data.totalRowsCount" [hidePageSize]="true"
                 [pageSize]="data.searchOptions.pageSize"
                 [pageIndex]="data.searchOptions.pageNumber"
                 *ngIf="data.totalRowsCount > perPageControl.value"></mat-paginator>

</ng-container>
