import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import {
  FormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AutoComplete } from "primeng/autocomplete";
import { BehaviorSubject, Observable, combineLatest, of } from "rxjs";
import { catchError, filter, finalize, map, take, tap } from "rxjs/operators";
import { DialogService } from "src/app/services/dialog.service";
import { ContactDataService } from "src/app/services/gp/contact-data.service";
import { UserService } from "src/app/services/shared/user.service";
import { AlertDialogParams } from "src/app/shared/components/alert-dialog/alert-dialog.component";
import { ContactSearchOptionsRequest } from "src/app/shared/models/contactSearchOptionsRequest.model";
import { ContactsGroupType } from "../contacts-group-type";
import { InvestorCommunicationService } from "../investor-communication.service";
import { InvestorSuggestion } from "../investor-suggestion";
import { RecipientType } from "../recipient-type";
import { TemplateService } from "../template.service";
import { InvestorCommunicationDialogData } from "./investor-communication-dialog-data";

@Component({
  selector: "terra-investor-communication-dialog",
  templateUrl: "./investor-communication-dialog.component.html",
  styleUrls: ["./investor-communication-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestorCommunicationDialogComponent implements OnInit {
  @HostListener("document:keydown.escape", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (
      event.key === "Escape" &&
      (event.target as Element).tagName === "INPUT" &&
      this.lastLayoutVisible
    ) {
      this.lastLayoutVisible = false;
      return;
    }
    this.originalClose.bind(this.dialogRef)();
  }

  @ViewChildren(AutoComplete) autoCompletes: QueryList<AutoComplete>;

  RecipientType = RecipientType;
  ContactsGroupType = ContactsGroupType;
  emailForm: UntypedFormGroup;
  filteredContacts$: BehaviorSubject<InvestorSuggestion[]> =
    new BehaviorSubject<InvestorSuggestion[]>(null);
  CCfilteredRecipients$: BehaviorSubject<InvestorSuggestion[]> =
    new BehaviorSubject<InvestorSuggestion[]>(null);
  BCCfilteredRecipients$: BehaviorSubject<InvestorSuggestion[]> =
    new BehaviorSubject<InvestorSuggestion[]>(null);
  lastLayoutVisible = false;
  public originalClose: Function;
  isSending$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  recipientsFields = [
    {
      formControlName: "recipients",
      suggestionsSubject: this.filteredContacts$,
      additionSuggestions: this.userService
        .getCurrentAgentOrganizationMembers()
        .pipe(
          map((members) =>
            members
              .filter((member) => member.firstName && member.lastName)
              .map((member) => ({
                id: member.id,
                displayName: [
                  member.firstName,
                  member.middleName,
                  member.lastName,
                ].join(" "),
                firstName: member.firstName,
                lastName: member.lastName,
                email: member.email,
                recipientType: RecipientType.User,
              }))
          )
        ),
      label: "Recipients:",
    },
    {
      formControlName: "bccRecipients",
      suggestionsSubject: this.BCCfilteredRecipients$,
      additionSuggestions: this.userService
        .getCurrentAgentOrganizationMembers()
        .pipe(
          map((members) =>
            members
              .filter((member) => member.firstName && member.lastName)
              .map((member) => ({
                id: member.id,
                displayName: [
                  member.firstName,
                  member.middleName,
                  member.lastName,
                ].join(" "),
                firstName: member.firstName,
                lastName: member.lastName,
                email: member.email,
                recipientType: RecipientType.User,
              }))
          )
        ),
      label: "BCC:",
    },
  ];
  constructor(
    public dialogRef: MatDialogRef<InvestorCommunicationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InvestorCommunicationDialogData,
    private fb: FormBuilder,
    private contactDataService: ContactDataService,
    private templateService: TemplateService,
    private investorCommunicationService: InvestorCommunicationService,
    private userService: UserService,
    private dialogService: DialogService
  ) {
    this.originalClose = this.dialogRef.close;
    this.dialogRef.close = this.closeDialog.bind(this);
  }

  ngOnInit(): void {

    this.emailForm = this.fb.group({
      recipients: new UntypedFormControl(
        this.data.defaultSuggestions,
        Validators.required
      ),
      bccRecipients: new UntypedFormControl(),
      subject: new UntypedFormControl(this.data.subject, Validators.required),
      message: new UntypedFormControl(''),
    });
    this.userService.accountDetails$
      .pipe(
        take(1),
        tap((account) =>
          this.emailForm.patchValue({
            bccRecipients: [
              {
                id: account.id,
                displayName: [
                  account.firstName,
                  account.middleName,
                  account.lastName,
                ].join(" "),
                firstName: account.firstName,
                lastName: account.lastName,
                email: account.email,
                recipientType: RecipientType.User,
              },
            ],
          })
        )
      )
      .subscribe();

    this.templateService
      .getById(this.data.contentTemplateId)
      .pipe(
        take(1),
        tap((templateModel) =>
          this.emailForm.patchValue({ message: templateModel.definition })
        )
      )
      .subscribe();
  }

  closeDialog() {}

  filterContacts(
    event: any,
    targetSubject: BehaviorSubject<InvestorSuggestion[]>,
    additionSuggestions: Observable<InvestorSuggestion[]>
  ) {
    const searchReq = new ContactSearchOptionsRequest();
    searchReq.filter = event.query;
    searchReq.isBaseSearch = true;
    const baseDataSource = combineLatest(
      [
        this.contactDataService.getList(searchReq).pipe(
          map((contactsContainer) =>
            contactsContainer.rows.map((contact) => ({
              id: contact.id,
              displayName: [
                contact.firstName,
                contact.middleName,
                contact.lastName,
              ].join(" "),
              firstName: contact.firstName,
              lastName: contact.lastName,
              email: contact.email,
              recipientType: RecipientType.Contact,
            }))
          )
        ),
        this.investorCommunicationService.getGroups(event.query).pipe(
          map((groups) =>
            groups.map((group) => ({
              ...group,
              recipientType: RecipientType.Group,
            }))
          )
        ),
        additionSuggestions,
      ].filter((src) => src)
    );

    baseDataSource
      .pipe(
        take(1),
        tap(([contacts, groups, addition]) =>
          targetSubject.next([
            ...groups,
            ...(addition?.length
              ? addition.filter((usr) => usr.displayName.includes(event.query))
              : []),
            ...contacts,
          ])
        )
      )
      .subscribe();
  }

  send() {
    this.isSending$.next(true);
    this.emailForm.markAllAsTouched();
    Object.values(this.emailForm.controls).forEach((control) =>
      control.markAsDirty()
    );
    this.emailForm.updateValueAndValidity();
    this.validateBeforeSending()
      .pipe(
        take(1),
        filter((result) => result),
        tap((_) => this.doSubmit())
      )
      .subscribe();
  }

  validateBeforeSending(): Observable<boolean> {
    if (!this.emailForm.valid) {
      this.isSending$.next(false);
      return of(false);
    }
    if (
      this.autoCompletes.some((ac) => !!ac.containerEL.nativeElement.value)
    ) {
      const dialogParams = new AlertDialogParams(
        "Email Verification Issue",
        `
      <div class="flex flex-column gap-1">
      <span
        >One or more recipients can't be recognized as you did not select them from the dropdown autocomplete list.</span
      >
      <span>Please ensure that all the recipients are selected from the list and try again.</span>
    </div>
      `,
        "Back to editing"
      );
      dialogParams.actionPosition = "right";
      return this.dialogService
        .alertDialog(dialogParams)
        .afterClosed()
        .pipe(
          map((_) => false),
          tap((_) => this.isSending$.next(false))
        );
    }
    return of(true);
  }

  doSubmit() {
    const submitModel = this.investorCommunicationService.generateSubmitModel(
      this.emailForm.value
    );
    this.investorCommunicationService
      .sendInvestorsMails(this.data.contentTemplateId, submitModel)
      .pipe(
        take(1),
        tap((_) => {
          this.originalClose.bind(this.dialogRef)({ result: true });
        }),
        catchError((_) =>
          this.originalClose.bind(this.dialogRef)({ result: false })
        ),
        finalize(() => this.isSending$.next(false))
      )
      .subscribe();
  }
}
