import {Component, OnInit, ChangeDetectionStrategy, ViewContainerRef} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {debounceTime, map, shareReplay, startWith, switchMapTo} from 'rxjs/operators';
import {combineLatest, Subject} from 'rxjs';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';

import {RecipientContactsTableService} from '../../../shared/components/recipient-contacts-display-table/recipient-contacts-table.service';
import {CreateEditRecipientDialogComponent} from '../components/create-edit-recipient-dialog/create-edit-recipient-dialog.component';
import {GpRecipientContactsDataService} from '../../../services/gp/gp-recipient-contacts-data.service';
import {SnackbarService} from '../../../services/snackbar.service';
import {ConfirmDialogParams} from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import {DialogService} from '../../../services/dialog.service';
import {RecipientReadDto} from '../../../shared/models/recipient-read-dto.model';
import { UnitRecipientPaymentsHistoryDialogComponent } from 'src/app/shared/components/unit/unit-payment/unit-recipient-payments-history/unit-recipient-payments-history-dialog/unit-recipient-payments-history-dialog.component';

@Component({
  selector: 'terra-recipients-contacts',
  templateUrl: './recipients-contacts.component.html',
  styleUrls: ['./recipients-contacts.component.scss'],
  providers: [RecipientContactsTableService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecipientsContactsComponent extends OnDestroyMixin implements OnInit {

  private refreshTags$ = new Subject<void>();
  private searchOptions$ = this.recipientContactsTableService.searchOptions$;
  private selectedTagIds$ = this.searchOptions$.pipe(map(s => s.tags));

  pageForm: UntypedFormGroup;
  allTags$ = this.refreshTags$.pipe(
    startWith([null]),
    switchMapTo(this.recipientContactsDataService.getTags()),
    shareReplay(1)
  );
  selectedTags$ = combineLatest([this.allTags$, this.selectedTagIds$]).pipe(
    map(([allTags, selectedTagIds]) => {
      return selectedTagIds.map(id => allTags[id]);
    }),
    shareReplay(1)
  );

  constructor(private fb: UntypedFormBuilder,
              private recipientContactsDataService: GpRecipientContactsDataService,
              private recipientContactsTableService: RecipientContactsTableService,
              private dialog: MatDialog,
              private vcr: ViewContainerRef,
              private snackbarService: SnackbarService,
              private dialogService: DialogService) {
    super();
  }

  ngOnInit(): void {
    this.generateForm();
  }

  /**
   * Generates an empty form for filters
   */
  private generateForm() {
    this.pageForm = this.fb.group({
      filter: '',
      tags: null
    });

    this.pageForm.controls.filter.valueChanges.pipe(
      untilComponentDestroyed(this),
      debounceTime(300)
    ).subscribe(term => {
      this.recipientContactsTableService.updateFilter(term);
    });

    this.pageForm.controls.tags.valueChanges.pipe(
      untilComponentDestroyed(this),
      debounceTime(300)
    ).subscribe(tags => {
      this.recipientContactsTableService.updateTagsFilter(tags);
    });
  }

  /**
   * Opens a modal for creation a new recipient
   */
  public openCreateEditRecipientModal(recipientIdToEdit: number = 0) {
    const dialogConfig = new MatDialogConfig<number>();
    dialogConfig.disableClose = true;
    dialogConfig.viewContainerRef = this.vcr;
    dialogConfig.data = recipientIdToEdit;

    this.dialog.open(CreateEditRecipientDialogComponent, dialogConfig).afterClosed().pipe(untilComponentDestroyed(this))
      .subscribe(toRefresh => {
        if (toRefresh) {
          this.snackbarService.showGeneralMessage(!!recipientIdToEdit ? 'Recipient updated successfully' : 'Recipient added successfully');
          this.recipientContactsTableService.refresh();
          this.refreshTags$.next();
        }
      });
  }

  /**
   * Displays a confirmation modal for deleting recipient(s)
   * @param ids Id(s) to delete
   */
  public showDeleteModal(ids: number[]) {
    const dialogParams = new ConfirmDialogParams();
    dialogParams.panelClass = 'delete-contact-dialog';
    dialogParams.actionLabel = `Delete`;
    if (ids.length === 1) {
      dialogParams.title = `are you sure you want to delete this recipient?`;
      dialogParams.description = `Recipient information and all their related data will be deleted immediately. You cannot undo this action.`;
    } else if (ids.length > 1) {
      dialogParams.title = `are you sure you want to delete ${ids.length} recipients?`;
      dialogParams.description = `Recipients information and all their related data will be deleted immediately. You cannot undo this action.`;
    }

    this.dialogService.confirmDialog(dialogParams)
      .afterClosed().pipe(untilComponentDestroyed(this))
      .subscribe((confirmed: boolean) => {
        if (confirmed && ids && ids.length > 0) {
          this.deleteRecipient(ids);
        }
      });
  }

  /**
   * Deletes recipients
   * @param recipientsIds Recipients ids to delete
   */
  private deleteRecipient(recipientsIds: number[]) {
    if (recipientsIds.length === 1) {
      this.recipientContactsDataService.delete(recipientsIds[0]).pipe(untilComponentDestroyed(this)).subscribe(
        response => {
          this.recipientContactsTableService.refresh();
          this.snackbarService.showGeneralMessage('Deleted');
        }
      );
    } else if (recipientsIds.length > 1) {
      this.recipientContactsDataService.deleteMultiple(recipientsIds).pipe(untilComponentDestroyed(this)).subscribe(
        response => {
          this.recipientContactsTableService.refresh();
          this.snackbarService.showGeneralMessage('Deleted');
        }
      );
    }
  }

  /**
   * Opens payments history modal for specific recipient
   * @param recipient Recipient details
   */
  public openTransactionsHistoryModal(recipient: RecipientReadDto) {
    const dialogConfig = new MatDialogConfig<RecipientReadDto>();
    dialogConfig.disableClose = true;
    dialogConfig.viewContainerRef = this.vcr;
    dialogConfig.data = recipient;
    this.dialog.open(UnitRecipientPaymentsHistoryDialogComponent, dialogConfig);
  }
}
