<terra-dialog-header>
  <h1>{{ (isUpdatingExistingRecipient$ | async) ? 'Update Recipient' : 'Add a New Recipient' }}</h1>
</terra-dialog-header>

<terra-loader *ngIf="isLoading$ | async" class="loader"></terra-loader>

<mat-dialog-content [ngClass]="{'loading': isLoading$ | async}">

  <form [formGroup]="pageForm" class="form terra-error-style">

    <section class="one-line">
      <!--  Recipient First Name -->
      <div class="terra-form-field-wrapper">
        <label for="txtRecipientFirstName" class="text-input-label">Company/First Name</label>
        <mat-form-field appearance="outline">
          <input matInput id="txtRecipientFirstName" formControlName="firstName" placeholder="e.g. Dave"/>
          <mat-error *ngIf="pageForm.get('firstName').hasError('required')">
            Required
          </mat-error>
          <mat-error *ngIf="pageForm.get('firstName').hasError('illegalCharacter')">
            Name shouldn't contain the following characters: {{ forbiddenNameCharacters.join(', ') }}
          </mat-error>
          <mat-error *ngIf="pageForm.get('firstName').hasError('maxlength')">
            Enter a name up to 40 characters
          </mat-error>
        </mat-form-field>
      </div>

      <!--  Recipient Last Name -->
      <div class="terra-form-field-wrapper">
        <label for="txtRecipientLastName" class="text-input-label">Last Name (optional)</label>
        <mat-form-field appearance="outline">
          <input matInput id="txtRecipientLastName" formControlName="lastName" placeholder="Johnson"/>
          <mat-error *ngIf="pageForm.get('lastName').hasError('illegalCharacter')">
            Name shouldn't contain the following characters: {{ forbiddenNameCharacters.join(', ') }}
          </mat-error>
          <mat-error *ngIf="pageForm.get('lastName').hasError('maxlength')">
            Enter a name up to 40 characters
          </mat-error>
        </mat-form-field>
      </div>
    </section>

    <section class="one-line">
      <!--  Account Type -->
      <div class="terra-form-field-wrapper">
        <label for="accountType" class="text-input-label">Payment method</label>
        <mat-form-field appearance="outline">
          <mat-select id="accountType" placeholder="Select" formControlName="accountType">
            <mat-option [value]="null">Select</mat-option>
            <mat-option *ngFor="let paymentType of CrePaymentType.listAll()"
                        [value]="paymentType">{{ paymentType | enumString : CrePaymentType }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="pageForm.get('accountType').hasError('required')">
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <!--  Routing number -->
      <div class="terra-form-field-wrapper">
        <label for="txtRoutingNumber" class="text-input-label">US ABA Routing Number (RTN)</label>
        <mat-form-field appearance="outline">
          <input matInput id="txtRoutingNumber" formControlName="routingNumber" placeholder="e.g 458975213"/>
          <mat-error *ngIf="pageForm.get('routingNumber').hasError('required')">
            Required
          </mat-error>
          <mat-error *ngIf="pageForm.get('routingNumber').hasError('pattern')">
            Please enter a valid 9 digits routing number
          </mat-error>
        </mat-form-field>
      </div>
    </section>

    <section [ngClass]="pageForm.get('accountType')?.value === CrePaymentType.Wire ? 'half-line' : 'one-line'">
      <!--  Account number -->
      <div class="terra-form-field-wrapper">
        <label for="txtAccountNumber" class="text-input-label">Account Number</label>
        <mat-form-field appearance="outline">
          <input matInput id="txtAccountNumber" formControlName="accountNumber" placeholder="e.g 135494278"/>
          <mat-error *ngIf="pageForm.get('accountNumber').hasError('required')">
            Required
          </mat-error>
          <mat-error *ngIf="pageForm.get('accountNumber').hasError('pattern')">
            Please enter a valid account number
          </mat-error>
        </mat-form-field>
      </div>

      <div class="terra-form-field-wrapper" *ngIf="pageForm.get('accountType')?.value !== CrePaymentType.Wire">
        <label for="ddlAccountType" class="text-input-label">
          Account Type
        </label>
        <mat-form-field appearance="outline">
          <mat-select id="ddlAccountType" placeholder="Select" formControlName="checkingOrSavingsType" [hideSingleSelectionIndicator]="true">
            <mat-option *ngFor="let accountType of checkingOrSavingsTypes" [value]="accountType">
              {{ CheckingOrSavingsType.toString(accountType) }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </section>

    <!-- Wire memo OBI -->
    <div class="terra-form-field-wrapper triple-field" [@fadeInOut]>
      <label for="memo" class="text-input-label">Memo / Special Instruction (optional)
        <mat-icon class="info-icon-tooltip" pTooltip="{{memoTooltipText}}" tooltipPosition="top">help_outline</mat-icon>
      </label>
      <mat-form-field appearance="outline">
        <input matInput #memo id="memo" type="text" [formControl]="pageForm.get('memo')" placeholder="e.g Real Estate inc. Account Number: 123456789" maxlength="{{memoLength}}"/>
        <span matSuffix>{{ (memo.value?.length || 0) }}/{{ memoLength }}</span>
        <mat-error *ngIf="pageForm.get('memo')?.hasError('maxlength')">
          Enter a description up to {{ memoLength }} characters
        </mat-error>
      </mat-form-field>
    </div>

    <section class="" *ngIf="isWirePaymentType()">
      <terra-address-form class="form-fields address-form" partialFormName="address" street2Label="Second line of address" streetLabel="Street address"
                          [externalForm]="pageForm" [formSettings]="generateAddressFormSettings" [lockUsaCountry]="true" (formReady)="addressFormReady($event)">
      </terra-address-form>
    </section>

    <section class="one-line">
      <!--  Tags -->
      <terra-contact-tags (addTagAsChip)="addTag($event)" (removeTag)="removeTag($event)"
                          [toAutoCompleteTags]="false" [selectedContactTags]="currentTags"
                          [tagsFromControl]="pageForm.get('tags')">
        Using tags to describe recipients makes matching them to payments easier.
      </terra-contact-tags>
    </section>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button type="button" (click)="save()" mat-flat-button color="primary" class="save-button">Save</button>
  <button type="button" mat-button class="cancel-button" [mat-dialog-close]="undefined">Cancel</button>

  <!-- Error message -->
  <div class="full-width" *ngIf="isSubmitted$ | async">
    <p *ngIf="pageForm.invalid" class="error-message">
      Please review and correct the fields marked in red
    </p>
    <p *ngIf="pageForm.valid && (isGeneralServerError$ | async)" #serverError class="error-message">
      <span [innerHTML]="generalErrorMessage"></span></p>

    <p *ngIf="pageForm.valid && (validationError$ | async)" #serverError class="error-message">
      <span>{{ validationError$.value }}</span></p>
  </div>
</mat-dialog-actions>
