import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  BehaviorSubject,
  Observable,
  Subscription,
  debounceTime,
  map,
  skip,
  switchMap,
  tap,
} from "rxjs";
import { ContactDataService } from "src/app/services/gp/contact-data.service";
import { ContactSearchOptionsRequest } from "src/app/shared/models/contactSearchOptionsRequest.model";
import { InvestorContactReqRes } from "../../models/investorContactReqRes.model";
import { ViewportScroller } from "@angular/common";
import { IDynamicComponent } from "src/app/shared/models/dynamic-component-loader/IDynamicComponent.model";
import InvestingEntityType from "src/app/dashboard/models/InvestingEntityType.enum";

@Component({
  selector: "terra-view-as-investor-contacts",
  templateUrl: "./view-as-investor-contacts.component.html",
  styleUrls: ["./view-as-investor-contacts.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewAsInvestorContactsComponent
  implements OnInit, OnDestroy, IDynamicComponent
{
  @Input() data: any;

  @ViewChild("uiElement", { static: false })
  public uiElement: ElementRef;

  totalCount: number = 0;
  isLoading$ = new BehaviorSubject<boolean>(true);
  search$ = new BehaviorSubject<ContactSearchOptionsRequest>(
    new ContactSearchOptionsRequest(25)
  );
  search = new ContactSearchOptionsRequest();
  contacts: InvestorContactReqRes[] = [];
  subscription: Subscription = new Subscription();

  constructor(
    private contactDataService: ContactDataService,
    private cdr: ChangeDetectorRef
  ) {
    this.search.pageSize = 25;
    this.search.pageNumber = 0;
    this.search.isBaseSearch = true;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngOnInit() {
    this.getContacts();
  }

  public getContacts() {
    try {
      this.subscription = this.search$
        .pipe(
          debounceTime(300),
          switchMap((search) => this.contactDataService.getList(search))
        )
        .subscribe((response) => {
          this.isLoading$.next(false);
          this.contacts = [...this.contacts, ...response.rows];
          this.search.pageNumber += 1;
          this.totalCount = response.totalCount;
          this.cdr.markForCheck();
        });
    } catch (error) {}
  }

  public onScrollLoadData() {
    const nativeElement = this.uiElement.nativeElement;
    if (
      nativeElement.clientHeight + Math.round(nativeElement.scrollTop) ===
        nativeElement.scrollHeight &&
      this.contacts.length !== this.totalCount
    ) {
      const search = this.search$.value;
      search.pageNumber += 1;
      this.search$.next(search);
    }
  }

  filterChanged(filter: string) {
    this.contacts = [];
    const search = this.search$.value;
    search.pageNumber = 0;
    search.filter = filter;
    search.isBaseSearch = true;
    this.search$.next(search);
    this.isLoading$.next(true);
  }

  clearSearch() {
    this.contacts = [];
    const search = this.search$.value;
    search.pageNumber = 0;
    search.filter = "";
    this.search$.next(search);
  }

  viewAsInvestorBtn(contact) {
    this.isLoading$.next(true);
    if (this.data && this.data.output) {
      this.data.output(contact);
    }
  }
}
