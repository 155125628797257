import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewContainerRef,
} from "@angular/core";
import {
  debounceTime,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  switchMapTo,
  take,
  tap,
} from "rxjs/operators";
import { combineLatest, Observable, Subject } from "rxjs";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Title } from "@angular/platform-browser";
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from "@w11k/ngx-componentdestroyed";

import { KeyValuePair } from "../../../shared/types/KeyValuePair.model";
import { ContactDataService } from "../../../services/gp/contact-data.service";
import { InvestorContactsTableService } from "../components/investor-contacts-display-table/investor-contacts-table.service";
import { ContactReferrerDataService } from "../../../services/gp/contact-referrer-data.service";
import { DialogService } from "../../../services/dialog.service";
import { AppSettingsService } from "../../../services/app-settings.service";
import { AnalyticsServiceNameModel, TelemetryService } from "telemetry-library";
import { UserService } from "../../../services/shared/user.service";
import { InvestorContactImportDialogComponent } from "../components/investor-contact-import-dialog/investor-contact-import-dialog.component";
import { ContactReferrersDialogComponent } from "../components/contact-referrers-dialog/contact-referrers-dialog.component";
import {
  ContactDialogData,
  InvestorContactDialogComponent,
} from "../components/investor-contact-dialog/investor-contact-dialog.component";
import { InvestorContactReqRes } from "../models/investorContactReqRes.model";
import { ConfirmDialogParams } from "../../../shared/components/confirm-dialog/confirm-dialog.component";
import { AlertDialogParams } from "../../../shared/components/alert-dialog/alert-dialog.component";
import { BaseResponseDto } from "../../../shared/models/BaseResponseDto.model";
import { UtilsService } from "../../../services/utils.service";
import { PermissionService } from "src/app/permission/permission.service";
import { ImpersonationService } from "../../../services/shared/impersonation.service";
import { InvestorCommunicationDialogComponent } from "../../shared/investor-communication/investor-communication-dialog/investor-communication-dialog.component";
import { SnackbarService } from "src/app/services/snackbar.service";
import { InvestorCommunicationResultSnackbarComponent } from "../../shared/investor-communication/investor-communication-result-snackbar/investor-communication-result-snackbar.component";
import PermissionLevel from "src/app/permission/enums/permissionLevel.enum";
import { RecipientType } from "../../shared/investor-communication/recipient-type";

@Component({
  selector: "terra-investors-contacts",
  templateUrl: "./investors-contacts.component.html",
  styleUrls: ["./investors-contacts.component.scss"],
  providers: [InvestorContactsTableService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestorsContactsComponent
  extends OnDestroyMixin
  implements OnInit
{
  showClearFilter$ = this.contactsTableService.searchOptions$.pipe(
    map(
      (searchOptions) =>
        (searchOptions.tags && searchOptions.tags.length > 0) ||
        searchOptions.filter.length > 0 ||
        searchOptions.contactReferrerId
    )
  );

  allowInvestorName$ = this.permissionService.allowInvestorName$;
  isLoadingViewAsInvestor$ = this.impersonationService.isLoadingImpersonation$;
  private searchOptions$ = this.contactsTableService.searchOptions$;
  refreshFilters$ = new Subject<void>();

  allContactReferrers$ = this.refreshFilters$.pipe(
    startWith([null]),
    switchMapTo(this.contactReferrerDataService.getAll()),
    shareReplay(1)
  );

  private contactTagsResponse$ = this.refreshFilters$.pipe(
    startWith([null]),
    switchMapTo(this.contactDataService.getContactTags())
  );

  allTags$: Observable<KeyValuePair<number, string>[]> =
    this.contactTagsResponse$.pipe(map((response) => response.tags));
  mostUsedTags$: Observable<KeyValuePair<string, number>[]> =
    this.contactTagsResponse$.pipe(map((response) => response.mostUsedTags));

  private selectedTagIds$ = this.searchOptions$.pipe(map((s) => s.tags));
  selectedTags$ = combineLatest([this.allTags$, this.selectedTagIds$]).pipe(
    map(([allTags, selectedTagIds]) => {
      return selectedTagIds.map((id) => allTags.find((t) => t.key === id));
    }),
    shareReplay(1)
  );
  selectedContacts: InvestorContactReqRes[] = [];

  pageForm: UntypedFormGroup;
  constructor(
    private viewContainerRef: ViewContainerRef,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private contactDataService: ContactDataService,
    private contactsTableService: InvestorContactsTableService,
    private contactReferrerDataService: ContactReferrerDataService,
    private fb: UntypedFormBuilder,
    private dialogService: DialogService,
    private titleService: Title,
    private appSettings: AppSettingsService,
    private telemetryService: TelemetryService,
    private userService: UserService,
    private utilsService: UtilsService,
    private permissionService: PermissionService,
    private impersonationService: ImpersonationService,
    private activatedRoute: ActivatedRoute,
    private snackbarService: SnackbarService
  ) {
    super();
  }

  ngOnInit() {
    this.generateForm();

    const providedContactId = this.getContactIdParamProvided();
    if (providedContactId) {
      const investingEntityId = this.getInvestingEntityIdIdParamProvided();
      this.showContactModal(providedContactId, investingEntityId);
    }
  }

  private getContactIdParamProvided(): number {
    const contactId = +this.route.snapshot.queryParams.contactId;
    return contactId && contactId > 0 ? contactId : null;
  }

  private getInvestingEntityIdIdParamProvided(): number {
    const investingEntityId =
      +this.route.snapshot.queryParams.investingEntityId;
    return investingEntityId && investingEntityId > 0
      ? investingEntityId
      : null;
  }

  clearForm() {
    this.pageForm.get("filter").setValue("");
    this.pageForm.get("multiselectTags").setValue(null);
    this.pageForm.get("contactReferrerId").setValue(null);
  }

  openInvestorCommunicationDialog() {
    this.permissionService
      .checkPermission(PermissionLevel.Editor)
      .pipe(
        take(1),
        switchMap(([result, _]) =>
          result
            ? this.dialogService
                .customDialog(InvestorCommunicationDialogComponent, {
                  contentTemplateId: 2,
                  defaultSuggestions: this.selectedContacts.map(
                    (selectedContact) => ({
                      displayName: [
                        selectedContact.firstName,
                        selectedContact.middleName,
                        selectedContact.lastName,
                      ].join(" "),
                      firstName: selectedContact.firstName,
                      lastName: selectedContact.lastName,
                      email: selectedContact.email,
                      id: selectedContact.id,
                      recipientType: RecipientType.Contact,
                    })
                  ),
                })
                .afterClosed()
                .pipe(
                  take(1),
                  filter((result) => result),
                  tap((result) =>
                    this.snackbarService.showSnackbarFromComponent(
                      InvestorCommunicationResultSnackbarComponent,
                      result,
                      2,
                      "investor-communication-message-snackbar"
                    )
                  )
                )
            : this.dialogService
                .alertDialog(
                  new AlertDialogParams(
                    "Not Permitted",
                    "You are a read-only user so you cannot perform this action!"
                  )
                )
                .afterClosed()
        )
      )
      .subscribe();
  }

  export() {
    this.contactsTableService.searchOptions$
      .pipe(
        take(1),
        switchMap((options) =>
          this.contactDataService
            .exportContacts(options)
            .pipe(untilComponentDestroyed(this))
        )
      )
      .subscribe((response) => {
        const filename = response;

        if (!!filename) {
          this.downloadFile(filename);
        } else {
        }
      });
  }

  import() {
    this.importContactsClickTelemetryEvent();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = "investor-contact-import-dialog";
    this.dialog
      .open(InvestorContactImportDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((isRefresh: boolean) => {
        if (isRefresh) {
          this.contactsTableService.refresh();
          this.refreshFilters$.next();
        }
      });
  }

  manageContactReferrers() {
    this.dialog.open(ContactReferrersDialogComponent);
  }

  showContactModal(contactId: number, investingEntityId: number = null) {
    const dialogConfig = new MatDialogConfig<ContactDialogData>();

    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.viewContainerRef = this.viewContainerRef;

    dialogConfig.data = {
      contactId,
      investingEntityId,
    };

    this.dialog
      .open(InvestorContactDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((contactDetails: InvestorContactReqRes) => {
        if (contactDetails) {
          this.refreshFilters$.next();
          this.contactsTableService.refresh();
        }
      });
  }

  showDeleteModal(ids: number[]) {
    const dialogParams = new ConfirmDialogParams();
    dialogParams.panelClass = "delete-contact-dialog";
    dialogParams.actionLabel = `Delete`;
    if (ids.length === 1) {
      dialogParams.title = `are you sure you want to delete this investor?`;
      dialogParams.description = `Investor information and all their related data will be deleted immediately. You cannot undo this action.`;
    } else if (ids.length > 1) {
      dialogParams.title = `are you sure you want to delete ${ids.length} investors?`;
      dialogParams.description = `Investors information and all their related data will be deleted immediately. You cannot undo this action.`;
    }

    this.dialogService
      .confirmDialog(dialogParams)
      .afterClosed()
      .pipe(untilComponentDestroyed(this))
      .subscribe((confirmed: boolean) => {
        if (confirmed && ids && ids.length > 0) {
          this.onDelete(ids);
        }
      });
  }

  public viewAsInvestor(contact: InvestorContactReqRes) {
    this.impersonationService.viewAsInvestor(contact).subscribe({
      error: (err) => {
        this.errorAlertModel();
      },
    });
  }

  onDelete(ids: number[]) {
    if (ids.length === 1) {
      this.contactDataService
        .delete(ids[0])
        .pipe(untilComponentDestroyed(this))
        .subscribe(
          (response) => {
            this.contactsTableService.refresh();
          },
          (error) => {
            this.errorAlertModel();
          }
        );
    } else if (ids.length > 1) {
      this.contactDataService
        .deleteMultiple(ids)
        .pipe(untilComponentDestroyed(this))
        .subscribe(
          (response) => {
            this.contactsTableService.refresh();
          },
          (error) => {
            if (error instanceof BaseResponseDto) {
              this.utilsService.alertErrorMessage(error);
            } else {
              this.errorAlertModel();
            }
          }
        );
    }
  }

  private generateForm() {
    this.pageForm = this.fb.group({
      multiselectTags: null,
      filter: "",
      contactReferrerId: null,
    });

    this.pageForm.controls.filter.valueChanges
      .pipe(untilComponentDestroyed(this), debounceTime(300))
      .subscribe((term) => {
        this.contactsTableService.updateFilter(term);
      });

    this.pageForm.controls.multiselectTags.valueChanges
      .pipe(untilComponentDestroyed(this), debounceTime(300))
      .subscribe((tags) => {
        this.contactsTableService.updateTagsFilter(tags);
      });

    this.pageForm.controls.contactReferrerId.valueChanges
      .pipe(untilComponentDestroyed(this), debounceTime(150))
      .subscribe((contactReferrerId) => {
        this.contactsTableService.updateContactReferrerIdFilter(
          contactReferrerId
        );
      });

    if (this.activatedRoute.snapshot.queryParams["search"]) {
      this.pageForm
        .get("filter")
        .setValue(this.activatedRoute.snapshot.queryParams["search"]);
    }
  }

  private errorAlertModel() {
    const dialogParams = new AlertDialogParams();

    dialogParams.title = `An error has occurred`;
    dialogParams.description = `An error has occurred. Please try again or contact support.`;
    dialogParams.actionLabel = `OK`;

    this.dialogService.alertDialog(dialogParams);
  }

  private downloadFile(filename: string) {
    const link = document.createElement("a");
    link.href = `${this.appSettings.resourceUrl}document/${filename}/`;
    link.download = filename;
    link.dispatchEvent(new MouseEvent("click"));
  }

  private importContactsClickTelemetryEvent() {
    this.userService.accountDetails$
      .pipe(take(1))
      .subscribe((clientDetails) => {
        this.telemetryService.create(
          {
            eventID: "27",
            eventTitle: "IMPORT CONTACTS (CLICKED)",
            organizationID: clientDetails.organizationDetails.id,
          },
          AnalyticsServiceNameModel.Mixpanel |
            AnalyticsServiceNameModel.Insights
        );
      });
  }

  private importContactsSendTelemetryEvent() {
    this.userService.accountDetails$
      .pipe(take(1))
      .subscribe((clientDetails) => {
        this.telemetryService.create(
          {
            eventID: "28",
            eventTitle: "IMPORT CONTACTS (SEND)",
            organizationID: clientDetails.organizationDetails.id,
          },
          AnalyticsServiceNameModel.Mixpanel |
            AnalyticsServiceNameModel.Insights
        );
      });
  }
}
