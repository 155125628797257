export enum Section {
  ContactGenralDetails = 1,
  ContactAddress = 2,
  InvestingEntityGeneralDetails = 3,
  InvestingEntityAddress = 4
}

export enum CellDataType {
  General = 0,
  Text = 1,
  Email = 2,
  Phone = 3,
  CountryIso = 4,
  CurrencyIso = 5,
  Boolean = 6,
  Options = 7,
  UsState = 8
}

export class ContactImportResponse {
  public isSuccess: boolean;
  public invalidRows: InvalidRow[];
  public importedRowsCount: number;
  public skippedRowsCount: number;
}

export class InvalidRow {
  public row: number;
  public errors: ImportError[];
}

export class ImportError {
  dataType: CellDataType;
  section: Section;
  column: number;
  columnRef: string;

  required: boolean;
  invalidFormat: boolean;
  maxLength: number;
  minLength: number;
  mustBeEmpty: boolean;

  /** When the dataType is Options, the allowed options will be listed here. */
  options: string[];
}

export enum ColumnNumbers
  {
    FirstName = 1,
    MiddleName,
    LastName,
    Email,
    Phone,
    Referrer,
    Tags,
    Remarks,
    Contact_Street,
    Contact_City,
    Contact_State,
    Contact_HouseNumber,
    Contact_PostalCode,
    Contact_CountryIso,
    InvestingEntity_Name,
    InvestingEntity_TIN,
    InvestingEntity_Type,
    InvestingEntity_AccountingId,
    InvestingEntity_UseContactLocation,
    InvestingEntity_Street,
    InvestingEntity_City,
    InvestingEntity_State,
    InvestingEntity_HouseNumber,
    InvestingEntity_Zip,
    InvestingEntity_CountryIso
  }
