import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { ContactReferrersDialogState, ContactReferrersDialogStore } from './contact-referrers-dialog.store';


@Injectable()
export class ContactReferrersDialogQuery extends QueryEntity<ContactReferrersDialogState> {

  isUnsavedChanges$ = this.select(s => s.ui.isUnsavedChanges);
  isSaving$ = this.select(s => s.ui.isSaving);
  isSubmitted$ = this.select(s => s.ui.isSubmitted);

  constructor(protected store: ContactReferrersDialogStore) {
    super(store);
  }
}
