import { ChangeDetectionStrategy, Component, Inject, InjectionToken } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { HelpSourceTypeService } from 'src/app/services/help-source-type.service';
import { DialogHeaderComponent } from 'src/app/shared/components/dialog-header/dialog-header.component';
import { supportSharedData } from 'src/app/shared/components/support/support.component';
import { TerraUtils } from 'src/app/shared/TerraUtils';

export const WINDOW = new InjectionToken<Window>('Global window object', {
  factory: () => window
}); // TODO: move to shared


@Component({
  selector: 'terra-integration-request-dialog',
  standalone: true,
  imports: [DialogHeaderComponent, MatDialogModule, RouterModule, MatButtonModule],
  templateUrl: './integration-request-dialog.component.html',
  styleUrl: './integration-request-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntegrationRequestDialogComponent  {
  paths = TerraUtils.consts.paths;

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<IntegrationRequestDialogComponent>,
    private sourceTypeService: HelpSourceTypeService,
    @Inject(MAT_DIALOG_DATA) public data,
    @Inject(WINDOW) private window: Window,
  ) {
    this.dialogRef.updateSize('539px');
  }

  cancel() {
    this.dialogRef.close()
  }

  talkToUS() {
    this.sourceTypeService.issueInput$.next(this.data.hint);

    this.openNewTabWithData({issueInput: this.data.hint}, this.paths.SUPPORT);
    this.dialogRef.close()
  }


  private openNewTabWithData(data: supportSharedData, route: string): void {
    const url = this.router.createUrlTree([route]).toString();
    const newTab = this.window.open(url, '_blank');

    if (newTab) {
      // pass the shared Data to the new tab
      newTab.opener.sharedData = data;
    }
  }
}
