<div class="send-request-dialog-container">
    <terra-dialog-header [showCloseButton]="true">
        <h1>{{data.title}}</h1>
    </terra-dialog-header>
    <div class="content-send-request-dialog">
        <div class="description">
            {{data.description}}
        </div>
    </div>
   <div mat-dialog-actions >
        <button [mat-dialog-close] type="button" mat-button aria-label="Close" >
            Cancel
        </button>
        <a mat-flat-button class="primary-button" (click)="talkToUS()">Talk to Us</a> 
        </div>
</div>
