import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {BehaviorSubject, combineLatest, Subject} from 'rxjs';
import {distinctUntilChanged, finalize, map, shareReplay, take} from 'rxjs/operators';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {AnalyticsServiceNameModel, TelemetryService} from 'telemetry-library';

import {TerraUtils} from 'src/app/shared/TerraUtils';
import HTTP_STATUS_CODES from 'src/app/shared/enums/HttpStatusCodesEnum';
import {ContactDataService} from 'src/app/services/gp/contact-data.service';
import {ErrorMatcher, ErrorType} from 'src/app/shared/errors/ErrorMatcher';
import {UtilsService} from 'src/app/services/utils.service';
import {
  CellDataType,
  ColumnNumbers,
  ContactImportResponse,
  ImportError,
  InvalidRow,
  Section
} from 'src/app/shared/models/ContactImportResponse.model';
import {UserService} from 'src/app/services/shared/user.service';
import {SnackbarService} from 'src/app/services/snackbar.service';
import {TerraNumberPipe} from 'src/app/shared/pipes/TerraNumber.pipe';
import {BaseResponseDto} from '../../../../shared/models/BaseResponseDto.model';

@Component({
  selector: 'terra-investor-contact-import-dialog',
  templateUrl: './investor-contact-import-dialog.component.html',
  styleUrls: ['./investor-contact-import-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvestorContactImportDialogComponent extends OnDestroyMixin implements OnInit {

  pageForm = this.fb.group({
    file: [null, Validators.required]
  });

  isSubmitted = false;

  isLoading$ = new BehaviorSubject(false);
  isProcessing$ = new BehaviorSubject(false);
  generalServerErrorMessage$ = new BehaviorSubject(null);

  private getContactImportTemplateResponse$ =  this.contactDataService.getContactImportTemplate().pipe(shareReplay(1));

  private importResult$ = new Subject<ContactImportResponse>();
  private rawUserErrorsResponse$ = this.importResult$.pipe(
    map(result => result ? result.invalidRows : null), shareReplay(1));

  totalErrorsNumber$ = this.rawUserErrorsResponse$.pipe(map(allErrors => this.calcErrorsNumber(allErrors)),
    shareReplay(1));

  isShowAllErrors$ = new BehaviorSubject(false);

  displayShowAllErrorsButton$ = combineLatest([this.rawUserErrorsResponse$, this.isShowAllErrors$]).pipe(
    map(([allRowErrors, isShowAllErrors]) => allRowErrors && allRowErrors.length > 1 && !isShowAllErrors),
    shareReplay(1)
  );

  userErrors$ = combineLatest([this.rawUserErrorsResponse$, this.isShowAllErrors$])
  .pipe(
    map(([userErrors, isShowAllErrors]) => {
      if (userErrors) {
        return isShowAllErrors ? userErrors : userErrors.slice(0, 1);
      } else {
        return null;
      }
    }),
    shareReplay(1)
  );

  hasMoreErrorLines: boolean = false;

  supportedFileExtensions = TerraUtils.consts.supportedFileExtensions.CONTACTS_IMPORT;

  constructor(
    private contactDataService: ContactDataService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<InvestorContactImportDialogComponent>,
    private utilsService: UtilsService,
    private userService: UserService,
    private snackbarService: SnackbarService,
    private telemetryService: TelemetryService,
    private numberPipe: TerraNumberPipe
  ) {
    super();
  }

  ngOnInit() {
    this.handleFileInputValueChange();
  }

  getErrorText(row: number, error: ImportError): string {

    const errorLocation = this.GetErrorLocationRefString(row, error);
    let message = `In ${errorLocation}: `;

    if (error.required) {
      return message + this.getMessageForRequired(error);
    }

    if (error.maxLength) {
      message += `Please enter not more than ${this.numberPipe.transform(error.maxLength)} characters.`;
      return message;
    }

    if (error.minLength) {
      message += `Please enter at least ${this.numberPipe.transform(error.minLength)} characters.`;
      return message;
    }

    if (error.mustBeEmpty) {
      if (error.dataType === CellDataType.UsState) {
        if (error.section === Section.ContactAddress) {
          return message + `The cell must be empty for non-US countries.`;
        }
        if (error.section === Section.InvestingEntityAddress) {
          return message + `The cell must be empty for non-US countries, or if you selected the 'Use Contact Location' option.`;
        }
      } else if (error.section === Section.InvestingEntityAddress) {
        return message + `The cell must be empty since you selected to use the contact address.`;
      }
      return message + `This cell must be empty.`;
    }

    if (error.invalidFormat) {
      return message + this.getMessageForInvalidFormat(error);
    }

    return message + 'Please check the value and make sure it makes sense.';
  }

  downloadTemplate() {
    this.isLoading$.next(true);
    this.getContactImportTemplateResponse$
    .pipe(
      finalize(() => this.isLoading$.next(false))
    )
    .subscribe(fileUrl => {
        if (fileUrl) {
          this.utilsService.downloadFileFromUrl(fileUrl);
        }
      });
  }

  import() {
    this.isSubmitted = true;
    this.clearErrors();
    if (this.pageForm.valid) {
      this.isLoading$.next(true);
      this.isProcessing$.next(true);
      const metaFileLinkId = this.pageForm.value.file.id;
      this.contactDataService.createContactsAndInvestingEntitiesFromExcelFile(metaFileLinkId)
      .subscribe(
        result => {
          if (result.isSuccess) {
            let finalMessage = `${result.importedRowsCount} contacts/investing entities imported to the system`;
            if (result.skippedRowsCount) {
              finalMessage += `\n${result.skippedRowsCount} contacts/investing entities skipped`;
            }
            this.telemetrySuccess();
            this.snackbarService.showGeneralMessage(finalMessage, 7, 'flow-contact-import-success');
            this.dialogRef.close(true);
          } else {
            this.importResult$.next(result);
            this.isLoading$.next(false);
          }
        },
        error => {
          if (error instanceof BaseResponseDto) {
            this.utilsService.alertErrorMessage(error);
          } else if (ErrorMatcher.isError(error, ErrorType.BadFileFormatException)) {
            this.generalServerErrorMessage$.next('Failed importing contacts/investing entities. Please make sure the file type is .xlsx and that the content is formatted correctly');
          } else if (ErrorMatcher.isError(error, ErrorType.RecordNotFoundException)) {
            this.generalServerErrorMessage$.next('Failed importing contacts/investing entities. Try uploading the file again.');
          } else if (ErrorMatcher.isError(error, ErrorType.ValueNotSupportedException)) {
            if (error.error.data) {
              //   this.generalServerErrorMessage = `ValueNotSupportedException error`;
            } else {
              this.generalServerErrorMessage$.next(TerraUtils.consts.messages.GENERAL_SUBMIT_ERROR);
            }
          } else if (error.status === HTTP_STATUS_CODES.NOT_ACCEPTABLE) {
            this.generalServerErrorMessage$.next('Failed importing contacts/investing entities. Make sure the data is valid.');
          } else {
            this.generalServerErrorMessage$.next(TerraUtils.consts.messages.GENERAL_SUBMIT_ERROR);
          }
          this.isProcessing$.next(false);
          this.isLoading$.next(false);
        },
        () => {
          this.isProcessing$.next(false);
        }
      );
    }
  }

  private getInvestingEntitiesPageLink = (text) => `<a target="_blank" href="/info/investing-entity-types">${text}</a>`;

  private getUsStatesLink = (text) => `<a target="_blank" href="https://www.ssa.gov/international/coc-docs/states.html">${text}</a>`;

  private getUsCountriesLink = (text) => `<a target="_blank" href="https://countrycode.org/">${text}</a>`;

   /** When the user clears the file uploader, clear the error messages also. */
  private handleFileInputValueChange() {
    this.pageForm.get('file').valueChanges.pipe(untilComponentDestroyed(this), distinctUntilChanged())
      .subscribe(value => {
        if (value == null) {
          this.clearErrors();
        }
      });
  }

  private telemetrySuccess() {
    this.userService.accountDetails$.pipe(take(1))
    .subscribe(clientDetails => {
      this.telemetryService.create({
        eventID: '',
        eventTitle: 'CONTACTS IMPORT',
        additional: {
          organizationID: clientDetails.organizationDetails.id
        }
      }, AnalyticsServiceNameModel.Mixpanel | AnalyticsServiceNameModel.Insights);
    });
  }

  private getMessageForRequired(error: ImportError) {
    switch (error.dataType) {
      case CellDataType.General:
      case CellDataType.Text:
      default:
        return `A value is required.`;
      case CellDataType.Email:
        return `Email address is required`;
      case CellDataType.Phone:
        return `Phone number is required`;
      case CellDataType.CountryIso:
         return `An address must contain at least a country.`;
      case CellDataType.Boolean:
           return 'A value is required. (e.g.: 1, 0, yes, no, true, false)';
      case CellDataType.Options:
        const optionsStr = error.options && error.options.length > 0 ? error.options.join(', ') : null;
        return 'A value is required. ' + (optionsStr ? `Accepted values: ${optionsStr}` : '');
      case CellDataType.UsState:
        return `State is required (e.g.: "NY", "FL", "New York", "Florida", more examples can be found ${this.getUsStatesLink('here')}).`;
    }
  }

  private getMessageForInvalidFormat(error: ImportError) {
    switch (error.dataType) {
      case CellDataType.General:
      case CellDataType.Text:
      default:
        return `Valid entry is required.`;
      case CellDataType.Email:
        return `Invalid email address format`;
      case CellDataType.Phone:
        return `Invalid phone number format`;
      case CellDataType.CountryIso:
        return  `Invalid country code (e.g.: US, GB, IL, etc. All codes can be found ${this.getUsCountriesLink('here')}).`;
      case CellDataType.Boolean:
        return `Invalid value. Accepted values: yes, no, true, false, 1, 0. An empty cell is the same as 'no'.`;
      case CellDataType.Options:
        if (error.column == ColumnNumbers.InvestingEntity_Type) {
          const optionsStr = error.options && error.options.length > 0 ? error.options.slice(0, 3).join(', ') : null;
          return 'Invalid value. ' + (optionsStr ? `(example values: ${optionsStr}, full list can be found ${this.getInvestingEntitiesPageLink('here')})` : '');
        } else {
          const optionsStr = error.options && error.options.length > 0 ? error.options.join(', ') : null;
          return 'Invalid value. ' + (optionsStr ? `Accepted values: ${optionsStr}` : '');
        }
      case CellDataType.UsState:
          return `Invalid US state format. State codes and names are accepted. (e.g.: "NY", "FL", "New York", "Florida" etc.
          All codes can be found ${this.getUsStatesLink('here')})`;
    }
  }

  /** Returns the error location in the excel file. for example:  "in cell 4A", or "in row" */
  private GetErrorLocationRefString(rowNumber: number, error: ImportError) {
    // in other cases it's in a specific cell:
    if (error.columnRef) {
      return `cell '${error.columnRef}${rowNumber}'`;
    } else {
      // in some cases the error relates to the entire row:
      return `row ${rowNumber}`;
    }
  }

  private clearErrors() {
    this.generalServerErrorMessage$.next(false);
    this.isShowAllErrors$.next(false);
    this.importResult$.next(null);
    this.isProcessing$.next(false);
  }

  private calcErrorsNumber(invalidRows: InvalidRow[]): number {
    if (!invalidRows || invalidRows.length == 0 ) {
      return 0;
    }
    return invalidRows
    .map(row => row.errors.length)
    .reduce((totalErrors, currentRowErrorsNumber) => totalErrors + currentRowErrorsNumber);
  }
}
