import { StoreConfig, EntityState, EntityStore } from '@datorama/akita';
import { Injectable } from '@angular/core';

import { ContactReferrerReqRes } from 'src/app/shared/models/ContactReferrerReqRes.model';

export interface ContactReferrersDialogUiState {
  isUnsavedChanges: boolean;
  isSaving: boolean;
  isSubmitted: boolean;
}

export interface ContactReferrersDialogState extends EntityState<ContactReferrerReqRes, number> {
  ui: ContactReferrersDialogUiState;
}

export function createInitialState(): ContactReferrersDialogState {
  return {
    ui: {
      isUnsavedChanges: false,
      isSaving: false,
      isSubmitted: false,
    },
    allContactReferrers: []
  } as ContactReferrersDialogState;
}

@StoreConfig({ name: 'contact-referrers-dialog' })
@Injectable()
export class ContactReferrersDialogStore extends EntityStore<ContactReferrersDialogState> {
  constructor() {
    super(createInitialState());
  }
}
