<terra-dialog-header
  [showCloseButton]="true"
  (onCloseRequested)="originalClose.bind(dialogRef)()"
>
  <h1 class="large-title">
    {{ data.dialogTitle || "Notify Investors by Email" }}
  </h1>
</terra-dialog-header>

<div class="content-wrapper" [ngClass]="{ sending: (isSending$ | async) }">
  <terra-loader class="page-loader" *ngIf="isSending$ | async"></terra-loader>
  <mat-dialog-content>
    <form [formGroup]="emailForm" class="terra-error-style email-form">
      <div
        class="investor-communication-field"
        *ngFor="let recipientField of recipientsFields"
      >
        <label>{{ recipientField.label }}</label>
        <div class="auto-complete-error-container">
          <p-autoComplete
            [formControlName]="recipientField.formControlName"
            [suggestions]="recipientField.suggestionsSubject | async"
            [completeOnFocus]="true"
            (completeMethod)="
              filterContacts(
                $event,
                recipientField.suggestionsSubject,
                recipientField.additionSuggestions
              )
            "
            field="displayName"
            [multiple]="true"
            minLength="0"
            (onShow)="lastLayoutVisible = true"
            (onSelect)="lastLayoutVisible = false"
            (onBlur)="lastLayoutVisible = false"
          >
            <ng-template let-contact pTemplate="item">
              <div class="list-group-item">
                <div class="content">
                  <terra-name-icon
                    [names]="
                      contact.recipientType !== RecipientType.Group
                        ? [contact.firstName, contact.lastName]
                        : contact.type === ContactsGroupType.Holding
                        ? []
                        : contact.displayName.split(' ')
                    "
                    [icon]="
                      contact.recipientType === RecipientType.Group &&
                      contact.type === ContactsGroupType.Holding
                        ? 'building'
                        : null
                    "
                  ></terra-name-icon>
                  <div class="text">
                    <span class="name">{{
                      contact.displayName | truncate : 42 : true
                    }}</span>

                    <span
                      class="email"
                      *ngIf="contact.recipientType !== RecipientType.Group"
                      >{{ contact.email | truncate : 47 : true }}</span
                    >
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template let-contact pTemplate="selectedItem">
              <div class="selected-item">
                <terra-name-icon
                  class="selected-item-name-icon"
                  [names]="
                    contact.recipientType !== RecipientType.Group
                      ? [contact.firstName, contact.lastName]
                      : contact.type === ContactsGroupType.Holding
                      ? []
                      : contact.displayName.split(' ')
                  "
                  [icon]="
                    contact.recipientType === RecipientType.Group &&
                    contact.type === ContactsGroupType.Holding
                      ? 'building'
                      : null
                  "
                ></terra-name-icon>
                <span class="name">{{
                  contact.displayName | truncate : 42 : true
                }}</span>
              </div>
            </ng-template>
          </p-autoComplete>
          <mat-error
            *ngIf="
              emailForm
                .get(recipientField.formControlName)
                .hasError('required') &&
              emailForm.get(recipientField.formControlName).dirty
            "
            >Not Valid! Select from the suggestions list</mat-error
          >
        </div>
      </div>
      <div class="investor-communication-field">
        <label>Subject:</label>
        <mat-form-field appearance="outline">
          <input
            matInput
            id="txtSubject"
            formControlName="subject"
            class="lo_sensitive"
            placeholder="Insert the subject"
          />
          <mat-error *ngIf="emailForm.get('subject').hasError('required')"
            >Required</mat-error
          >
        </mat-form-field>
      </div>
      <quill-editor id="txtDescription" formControlName="message" format="html" class="lo_sensitive description-editor" placeholder="" />
    </form>
  </mat-dialog-content>
</div>

<div mat-dialog-actions class="right">
  <button
    (click)="originalClose.bind(dialogRef)()"
    type="button"
    mat-button
    aria-label="Cancel"
    class="cancel-button"
  >
    Cancel
  </button>
  <button
    mat-flat-button
    color="primary"
    cdkFocusInitial
    class="action-button"
    (click)="send()"
    [disabled]="isSending$ | async"
  >
    Send
  </button>
</div>
