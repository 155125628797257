import { Injectable } from '@angular/core';
import { Observable, merge, Subject, BehaviorSubject } from 'rxjs';
import { scan, map, shareReplay, distinctUntilChanged } from 'rxjs/operators';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';

import { ContactSearchOptionsRequest } from '../../../../shared/models/contactSearchOptionsRequest.model';
import { AppService, AppQuery } from 'src/app/state';

@Injectable()
export class InvestorContactsTableService extends OnDestroyMixin {

  private _refreshData$ = new Subject<void>();
  refreshData$ = this._refreshData$.asObservable();

  // The fundraising ID is not a filter that the user can control, and it is set even before connecting to the data source,
  // so we want it to store the current value and not just emit new values when the user changes a filter:
  private fundraisingId$ = new BehaviorSubject<number>(0);
  // The rest are filters that the user can control:
  private pageSize$ = new Subject<number>();
  private pageNumber$ = new Subject<number>();
  private tagsFilter$ = new Subject<number[]>();
  private contactReferrerIdFilter$ = new Subject<number>();
  private textFilter$ = new Subject<string>();

  private fundraisingIdSource$ = this.fundraisingId$.pipe(map(fundraisingId => ({ fundraisingId }) as ContactSearchOptionsRequest));
  private pageNumberSource$ = this.pageNumber$.pipe(map(pageNumber => ({ pageNumber }) as ContactSearchOptionsRequest));
  private pageSizeSource$ = this.pageSize$.pipe(map(pageSize => ({ pageSize }) as ContactSearchOptionsRequest));
  private textFilterSource$ = this.textFilter$.pipe(map(filter => filter.trim()), distinctUntilChanged(), map(filter => ({ filter }) as ContactSearchOptionsRequest));
  private tagsSource$: Observable<ContactSearchOptionsRequest> = this.tagsFilter$.pipe(map(tags => ({ tags: tags || [] }) as ContactSearchOptionsRequest));
  private contactReferrerIdSource$: Observable<ContactSearchOptionsRequest> = this.contactReferrerIdFilter$.pipe(map(contactReferrerId => ({ contactReferrerId }) as ContactSearchOptionsRequest));
  private uiStateSource$: Observable<ContactSearchOptionsRequest> = this.appQuery.gpUiPrefs.pipe(
    map(state => state.contacts.sort),
    map(sort => ({ orderBy: sort.orderBy, sortOrder: sort.direction }) as ContactSearchOptionsRequest));

  // Merged source of search options
  private searchOptionsSources$: Observable<ContactSearchOptionsRequest> = merge(
    this.uiStateSource$,
    this.fundraisingIdSource$,
    this.textFilterSource$,
    this.tagsSource$,
    this.contactReferrerIdSource$,
    this.pageNumberSource$,
    this.pageSizeSource$);

  // Search options state
  searchOptions$: Observable<ContactSearchOptionsRequest> = this.searchOptionsSources$
    .pipe(
      untilComponentDestroyed(this),
      scan((acc, value) => {
        // Whenever somehting changes, unless it's the pagenumber, return to the first page
        const pageNumber = value.pageNumber === undefined ? 0 : value.pageNumber;
        return { ...acc, ...value, pageNumber } as ContactSearchOptionsRequest;
      }, new ContactSearchOptionsRequest()),
      shareReplay(1)
    );

  constructor(private appService: AppService, private appQuery: AppQuery) {
    super();
  }

  refresh() {
    this._refreshData$.next();
  }

  sort(orderBy: string, sortOrder: string) {
    this.appService.updateContactsSortState({ orderBy, direction: sortOrder === 'desc' ? 'desc' : 'asc' });
  }

  updateFilter(term: string) {
    this.textFilter$.next(term);
  }

  updateTagsFilter(tags: number[]) {
    this.tagsFilter$.next(tags);
  }

  updateContactReferrerIdFilter(contactReferrerId: number) {
    this.contactReferrerIdFilter$.next(contactReferrerId);
  }

  updatePageSize(pageSize: number) {
    this.pageSize$.next(pageSize);
  }

  updatePageNumber(page: number) {
    this.pageNumber$.next(page);
  }

  updateFundraisingId(fundraisingId: number) {
    this.fundraisingId$.next(fundraisingId);
  }
}
