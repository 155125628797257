<terra-dialog-header>
  <h1>Import your contacts</h1>
  <a href="https://help.covercy.com/how-to-import-contacts-and-investing-entities" target="_blank">Learn more...</a>
</terra-dialog-header>

<ng-template #loader>
  <terra-loader class="dialog-loader" />
</ng-template>

<mat-dialog-content *ngIf="!(isLoading$ | async); else loader">
  <form id="pageForm" (ngSubmit)="import()" class="pb-2"  [formGroup]="pageForm" (keydown.enter)="$event.preventDefault()">
    <div class="cov-form-grid">
      <div class="full-width">
        <ol class="instructions-list">
          <li>
            <a (click)="downloadTemplate()" class="example-file-link light link">
              Download contacts import template</a> (if you haven't already)
          </li>
          <li>
            Add contacts details, and their investing entities (optional)
          </li>
          <li>Upload the updated file using the button below, and click "Import"</li>
        </ol>
        <terra-file-uploader no-type-select [supportedExtensions]="supportedFileExtensions" formControlName="file"
          [isSubmitted]="isSubmitted" class="full-width" />
      </div>
    </div>
    <!-- Error message -->
    <p *ngIf="generalServerErrorMessage$ | async as generalServerErrorMessage" class="error-message">
      {{ generalServerErrorMessage }}
    </p>
    <div class="user-errors-list error-message" *ngIf="(userErrors$ | async) as userErrors">
      <p>Failed to import contacts/investing entities. The file has the following issue(s):</p>
      <ul class="error-list">
        <ng-container *ngFor="let rowError of (userErrors$ | async)">
          <li *ngFor="let userError of rowError.errors" [innerHtml]="getErrorText(rowError.row, userError)"></li>
        </ng-container>
      </ul>
      <p *ngIf="displayShowAllErrorsButton$ | async">
        <a (click)="isShowAllErrors$.next(true)" class="light link">
          Show all {{ totalErrorsNumber$ | async }} errors
        </a>
      </p>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button type="submit" [disabled]="isProcessing$ | async" form="pageForm" class="page-cta" color="primary" mat-flat-button>
    <span>Import</span>
    <terra-loader *ngIf="isProcessing$ | async" />
  </button>
  <button [disabled]="isProcessing$ | async" type="button" mat-button class="cancel-button tall-button" [mat-dialog-close]="undefined">
    Cancel
  </button>
</mat-dialog-actions>


