<form [formGroup]="pageForm">
  <div class="actions">
    <div class="filter-bar">
      <!-- Add new recipient -->
      <button class="create-new-contact-button tall-button" type="button" mat-flat-button color="primary" (click)="openCreateEditRecipientModal()">
        <mat-icon class="notranslate">add</mat-icon> Add a New Recipient
      </button>

      <mat-form-field appearance="outline" class="text-filter-wrapper remove-error-place">
        <input matInput formControlName="filter" class="lo_sensitive" placeholder="Advanced Search" />
      </mat-form-field>

      <div class="tags-filter-wrapper" *ngIf="allTags$ | async as allTags">
        <mat-form-field appearance="outline" *ngIf="allTags" class="remove-error-place">
          <mat-select  matInput placeholder="Filter by tags" formControlName="tags" multiple>
            <mat-option *ngFor="let tag of allTags | keyvalue" [value]="tag.key">{{ tag.value }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-chip-grid class="terra-chip">
          <mat-chip *ngFor="let tag of (selectedTags$ | async)">
            {{ tag }}
          </mat-chip>
        </mat-chip-grid>
      </div>
    </div>
  </div>
</form>


<terra-recipient-contacts-display-table (editRow)="openCreateEditRecipientModal($event)"
                                        (deleteRow)="showDeleteModal($event)" (viewTransactionHistory)="openTransactionsHistoryModal($event)">
</terra-recipient-contacts-display-table>
