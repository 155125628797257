import {Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy} from '@angular/core';
import {debounceTime, shareReplay, map, switchMapTo, first, withLatestFrom} from 'rxjs/operators';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {SelectionModel} from '@angular/cdk/collections';
import {PageEvent} from '@angular/material/paginator';
import {merge, Observable, of} from 'rxjs';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Sort} from '@angular/material/sort';

import {InvestingEntityDataSource} from './investingEntityDataSource';
import {InvestingEntityTableService} from './investing-entity-table.service';
import InvestingEntityType from 'src/app/dashboard/models/InvestingEntityType.enum';
import {InvestingEntityReqRes} from 'src/app/dashboard/models/InvestingEntityReqRes.model';
import {GpInvestingEntityDataService} from 'src/app/services/gp/gp-investing-entity-data.service';

import {
  getArrayCommonById,
  getArrayDifferentById,
  getArrayIncludeAllById,
  getArrayIncludeSomeById, TerraUtils
} from 'src/app/shared/TerraUtils';
import { PermissionService } from 'src/app/permission/permission.service';
import { AccreditationService } from 'src/app/dashboard/shared/accreditation/accreditation.service';
import AccreditationStatus from 'src/app/shared/enums/AccreditationStatus.enum';

@Component({
  selector: 'terra-investing-entities-display-table',
  templateUrl: './investing-entities-display-table.component.html',
  styleUrls: ['./investing-entities-display-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvestingEntitiesDisplayTableComponent extends OnDestroyMixin implements OnInit {
  allowInvestorName$ = this.permissionService.allowInvestorName$;

  @Input() showMessageWhenListIsEmpty = true;
  @Input() messageWhenListIsEmpty:string = 'Add a new contact or import your contact list';
  @Input() excludeAgentContactInvestingEntities = false;
  @Input() showSelectionPreview = true;

  @Output() editRow: EventEmitter<number> = new EventEmitter();
  @Output() deleteRow: EventEmitter<number[]> = new EventEmitter();

  InvestingEntityType = InvestingEntityType;

  itemsPerPageOptions = [25, 50, 100, 200, 500];
  perPageControl = new UntypedFormControl(this.itemsPerPageOptions[0]);
  perPageChanged$: Observable<number> = this.perPageControl.valueChanges.pipe(untilComponentDestroyed(this), debounceTime(200));

  get selectedInvestingEntities() {
    if (!this.selection) {
      return [];
    }
    return this.selection.selected;
  }

  dataSource = new InvestingEntityDataSource(this.gpInvestingEntityDataService, this.investingEntityTableService);

  searchOptions$ = this.investingEntityTableService.searchOptions$;

  pageRowsCount$ = this.dataSource.pageRows$.pipe(map(rows => rows ? rows.length : 0), shareReplay(1));
  totalRowsCount$ = this.dataSource.totalRowsCount$;

  isLoading$ = this.dataSource.isLoading$;

  selection = new SelectionModel<InvestingEntityReqRes>(true, []);
  displayedColumns: string[] = ['select', 'name', 'email', 'tags', 'accreditationDate', 'personalRemarks', 'action'];

  isAllSelectedInPage$ = merge(this.dataSource.pageRows$, this.selection.changed).pipe(
    switchMapTo(this.dataSource.pageRows$),
    map(rows => {
      return getArrayIncludeAllById(rows, this.selection.selected);
    })
  );

  isSomeSelectedInPage$ = merge(this.dataSource.pageRows$, this.selection.changed).pipe(
    switchMapTo(this.dataSource.pageRows$),
    map(rows => {
      return getArrayIncludeSomeById(rows, this.selection.selected);
    })
  );


  selectAllMessages = false;

  constructor(
    private investingEntityTableService: InvestingEntityTableService,
    private gpInvestingEntityDataService: GpInvestingEntityDataService,
    private permissionService: PermissionService,
    private accreditationService: AccreditationService) {
    super();
  }

  ngOnInit() {
    if (this.excludeAgentContactInvestingEntities) {
      this.investingEntityTableService.excludeAgentContacts();
    }
    this.investingEntityTableService.updatePageSize(this.itemsPerPageOptions[0]);

    this.perPageChanged$.pipe(untilComponentDestroyed(this)).subscribe(
      pageSize => {
        this.investingEntityTableService.updatePageSize(pageSize);
      });

  }

  trackByFunction(index: number, item: InvestingEntityReqRes) {
    return item.id;
  }

  pageChange(event: PageEvent) {
    this.investingEntityTableService.updatePageNumber(event.pageIndex);
  }

  rowCheckToggle(row: InvestingEntityReqRes, unselectOthers = false, event = null) {
    if (event && (event.ctrlKey || event.metaKey)) {
      unselectOthers = false;
    }

    const isRowChecked = this.isRowChecked(row);
    const currentNumberOfSelectedItems = this.selectedInvestingEntities.length;

    if (unselectOthers) {
      this.clearSelection();
    }

    if (isRowChecked) {
      this.selectAllMessages = false;
    }

    if (isRowChecked && (currentNumberOfSelectedItems === 1 || !unselectOthers)) {
      this.selection.deselect(row);
      // after pagination selection.selected.#item hash will be differ then row, need to find row in selection.selected and deselect
      this.selection.deselect(this.selection.selected.find(x => x.id === row.id));
    } else {
      this.selection.select(row);
    }
  }

  isRowChecked(row: InvestingEntityReqRes) {
    return this.selection.selected.some(x => x.id === row.id);
  }

  masterToggle(event) {
    if (event.checked) {
      this.dataSource.pageRows$.pipe(
        first(),
        withLatestFrom(this.dataSource.totalRowsCount$)
      ).subscribe(
        ([rows, totalRows]) => {
          const x_ids_to_add = getArrayDifferentById(rows, this.selection.selected);
          x_ids_to_add.forEach((id) => {
            this.selection.select(rows.find(r => r.id === id));
          });
          if (rows.length === totalRows) {
            this.selectAllMessages = true;
          }
        }
      );
    } else {
      this.dataSource.pageRows$.pipe(first()).subscribe(rows => {
        const x_ids_to_remove = getArrayCommonById(rows, this.selection.selected);
        x_ids_to_remove.forEach((id) => {
          this.selection.deselect(this.selection.selected.find(r => r.id === id));
        });
        this.selectAllMessages = false;
      });
    }
  }

  toggleAllMessages(isSelectAllMessages) {
    this.selectAllMessages = isSelectAllMessages;
    if (isSelectAllMessages) {
      this.dataSource.getLoader().next(true);
      this.gpInvestingEntityDataService.getAll()
        .subscribe(
          response => {
            const x_ids_to_add = getArrayDifferentById(response, this.selection.selected);
            x_ids_to_add.forEach((id) => {
              this.selection.select(response.find(r => r.id === id));
            });
          },
          err => {
            console.error(err);
          },
          () => {
            this.dataSource.getLoader().next(false);
          });
    } else {
      this.selection.clear();
    }
  }

  deleteMultiple() {
    if (this.selection.selected.length === 0) {
      return;
    }

    const ids = this.selection.selected.map(i => i.id);

    this.deleteRow.emit(ids);
  }

  onEditRow(id: number) {
    if (!this.editRow) {
      return;
    }
    this.editRow.emit(id);
  }

  onDeleteRow(id: number) {
    if (!this.deleteRow) {
      return;
    }
    this.deleteRow.emit([id]);
  }

  showActions(model: any) {
    if (this.selection.selected.length === 1) {
      if (model.id === this.selection.selected[0].id) {
        return true;
      }
      return false;
    }

    if (model.hover) {
      return true;
    }
    return false;
  }

  sortData(sort: Sort) {
    if (!sort.active || !sort.direction) {
      return;
    }
    this.investingEntityTableService.sort(sort.active, sort.direction);
  }

  isAccredited(investingEntity: InvestingEntityReqRes):boolean{
    return investingEntity.accreditation?.status === AccreditationStatus.Accredited;
  }

  public investorAccreditationDate(accreditationDate: Date): Date {
    return TerraUtils.forceUtcToLocal(accreditationDate);
  }

  private clearSelection() {
    this.selection.clear();
  }
}
