import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';

import {HttpService} from '../http.service';
import {UnitBankAccountReqRes} from 'src/app/shared/models/gp/UnitBankAccountReqRes.model';
import {UnitBankAccountListItem} from 'src/app/shared/models/gp/UnitBankAccountListItem.model';
import {AbstractRestService} from 'src/app/shared/types/AbstractRestService';
import {UnitTransactionReqRes} from '../../shared/models/gp/UnitTransactionReqRes.model';
import {UnitBankAccountBalanceReqRes} from '../../shared/models/gp/UnitBankAccountBalanceReqRes.model';
import {UnitAchLimits} from '../../shared/models/unit-bank-account/UnitAchLimits.model';
import {SearchOptionsResponse} from 'src/app/shared/models/SearchOptionsResponse.model';
import {UnitBankAccountSearchOptions} from 'src/app/shared/models/unit-bank-account/UnitBankAccountSearchOptions.model';
import {UnitBankAccountDataService} from '../shared/unit-bank-account-data.service';
import {UnitBankAccountTransactionsSearchOptionsRequest} from 'src/app/shared/models/unit-bank-account/UnitBankAccountTransactionsSearchOptionsRequest.model';
import {CreBankAccountSearchOptionsResponse} from "../../shared/models/CreBankAccountSearchOptionsResponse.model";
import {UnitAccountLimits} from 'src/app/shared/models/unit-bank-account/UnitAccountLimits.model';
import {UnitTransactionNotes} from 'src/app/shared/models/unit-transaction/UnitTransactionNotes.model';

@Injectable()
export class CreBankAccountDataService extends AbstractRestService<UnitBankAccountReqRes, UnitBankAccountListItem> implements UnitBankAccountDataService {
  readonly baseEndpoint$ = of('cre/accounts/');

  constructor(http: HttpService) {
    super(http);
  }

  getSearchOptionsToQuerystring(options: UnitBankAccountSearchOptions): string {
    let queryString = super.getSearchOptionsToQuerystring(options);

    if (options?.parentType != null) {
      queryString += `&parentType=${options.parentType}`;
    }

    queryString += `&includeLimits=${options.includeLimits || false}`;
    queryString += `&includeClosed=${options.includeClosed || false}`;

    return queryString;
  }

  public getListBasic(options: UnitBankAccountSearchOptions): Observable<SearchOptionsResponse<UnitBankAccountListItem>> {
    return super.getList(options);
  }

  public getUnitBankAccounts(options: UnitBankAccountSearchOptions) {
    const queryString = this.getSearchOptionsToQuerystring(options);
    return this.baseEndpoint$.pipe(
      switchMap(baseEndpoint => this.http.getTyped<CreBankAccountSearchOptionsResponse<UnitBankAccountListItem>>(`${baseEndpoint}` + queryString)));
  }

  /** Allows the current user to approve the bank account.
   * If this user is the lst one to sign,
   * it will also create the bank account in Unit.
   */
  public approve(bankAccountId: number) {
    return this.baseEndpoint$.pipe(
      switchMap(baseEndpoint => this.http.put(`${baseEndpoint}${bankAccountId}/approve`, null))
    );
  }

  /**
   * Get transactions list for the bank account
   * @param bankAccountId: UnitResourceId
   */
  public getTransactionsList(bankAccountId: number, options: UnitBankAccountTransactionsSearchOptionsRequest): Observable<UnitTransactionReqRes[]> {
    const querystring = options ? this.getTransactionsSearchOptionsToQuerystring(options) : '';
    return this.baseEndpoint$.pipe(
      switchMap(baseEndpoint => this.http.getTyped<UnitTransactionReqRes[]>(`${baseEndpoint}${bankAccountId}/transactions` + querystring))
    );
  }

  public getTransactionsForHolding(holdingId: number, options: UnitBankAccountTransactionsSearchOptionsRequest): Observable<UnitTransactionReqRes[]> {
    const querystring = options ? this.getTransactionsSearchOptionsToQuerystring(options) : '';
    return this.baseEndpoint$.pipe(
      switchMap(baseEndpoint => this.http.getTyped<UnitTransactionReqRes[]>(`${baseEndpoint}holding/${holdingId}/transactions` + querystring))
    );
  }

  public getBankAccountsForOrganization(options: UnitBankAccountSearchOptions): Observable<CreBankAccountSearchOptionsResponse<UnitBankAccountListItem>> {
    const querystring = options ? this.getSearchOptionsToQuerystring(options) : '';
    return this.baseEndpoint$.pipe(
      switchMap(baseEndpoint => this.http.getTyped<CreBankAccountSearchOptionsResponse<UnitBankAccountListItem>>(`${baseEndpoint}organization` + querystring)),
    );
  }

  /**
   * Gets account's balance, total expenses and total income
   * @param bankAccountId Unit bank account id
   */
  public getBalanceAndTotalTransactions(bankAccountId: number): Observable<UnitBankAccountBalanceReqRes> {
    return this.baseEndpoint$.pipe(
      switchMap(baseEndpoint => this.http.getTyped<UnitBankAccountBalanceReqRes>(`${baseEndpoint}${bankAccountId}/balance`)),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  /**
   * Get statement pdf
   * @param statementId Unit bank account statement id
   * @param bankAccountId Unit bank account id
   */
  getBankAccountStatement(statementId: string, bankAccountId: string) {
    return this.baseEndpoint$.pipe(
      switchMap(baseEndpoint => this.http.downloadFile(`${baseEndpoint}${bankAccountId}/statement/${statementId}`, 'application/pdf')),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  /**
   * Gets unit account types
   */
  getUnitAccountTypes() {
    return this.baseEndpoint$.pipe(
      switchMap(baseEndpoint => this.http.getTyped<UnitBankAccountBalanceReqRes>(`${baseEndpoint}types`)),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  /**
   * Get bank account ACH limits
   * @param bankAccountId Bank account id
   */
  getBankAccountAchLimits(bankAccountId: number): Observable<UnitAccountLimits> {
    return this.baseEndpoint$.pipe(
      switchMap(baseEndpoint => this.http.getTyped<UnitAccountLimits>(`${baseEndpoint}${bankAccountId}/limits/ach`)),
      catchError(err => {
        return throwError(err);
      }
      )
    );
  }

  getTransactionsSearchOptionsToQuerystring(options: UnitBankAccountTransactionsSearchOptionsRequest): string {
    const baseSearchOptionsQueryString = super.getSearchOptionsToQuerystring(options);

    const startDate = options.startDate?.toISOString() || '';
    const endDate = options.endDate?.toISOString() || '';
    const typeName = options.typeName || '';
    const excludeFees = options.excludeFees || '';

    return baseSearchOptionsQueryString + `&startDate=${startDate}&endDate=${endDate}&typeName=${typeName}&excludeFees=${excludeFees}`;
  }

  public toggleDistribution(bankAccountId: number): Observable<boolean> {
    return this.baseEndpoint$.pipe(
      switchMap(baseEndpoint => this.http.getTyped<boolean>(`${baseEndpoint}${bankAccountId}/toggle-distribution`)),
      catchError(err => {
        return throwError(err);
      }
      )
    );
  }

  public downloadFaqPdf(bankAccountId: number): Observable<any> {
    return this.baseEndpoint$.pipe(
      switchMap(baseEndpoint => this.http.downloadFile(`${baseEndpoint}${bankAccountId}/download-faq`, 'application/pdf')),
      catchError(err => {
        return throwError(err);
      }));
  }

  public generateVerificationLetter(bankAccountId: number, includeBalance: boolean): Observable<any> {
    return this.baseEndpoint$.pipe(
      switchMap(baseEndpoint => this.http.downloadFile(`${baseEndpoint}${bankAccountId}/${includeBalance}/verification-letter`, 'application/pdf')),
      catchError(err => {
        return throwError(err);
      }));
  }

  // public exportTransactions(fileType: 'excel' | 'csv', unitBankId: number, textSearch: string = null, startDate: Date = null, endDate: Date = null): Observable<string> {
  public exportTransactions(fileType: 'excel' | 'csv', unitBankId: number, options: UnitBankAccountTransactionsSearchOptionsRequest): Observable<string> {
    const queryParams = [
      (options ? this.getTransactionsSearchOptionsToQuerystring(options) : ''),
      `fileType=${fileType}`
    ];

    let queryParamsStr = queryParams.join('&');
    if (!queryParamsStr.startsWith('?')) {
      queryParamsStr = '?' + queryParamsStr;
    }
    return this.baseEndpoint$.pipe(
      switchMap(baseEndpoint => this.http.getTyped<string>(`${baseEndpoint}${unitBankId}/transactions/export` + (queryParams.length ? `${queryParamsStr}` : ''))),
      catchError(err => {
        return throwError(err);
      }
      )
    );
  }

  public closeAccount(bankAccountId: number): Observable<boolean> {
    return this.baseEndpoint$.pipe(
      switchMap(baseEndpoint => this.http.putTyped<boolean>(`${baseEndpoint}${bankAccountId}/close`)),
      catchError(err => {
        return throwError(err);
      }));
  }

  createTransactionNotes(transactionNotes: UnitTransactionNotes): Observable<UnitTransactionNotes> {
    return this.baseEndpoint$.
      pipe(
        switchMap(baseUrl =>
          this.http.postTyped<UnitTransactionNotes>(`${baseUrl}/transactionNote/create`, transactionNotes)
        )
      );
  }

  updateTransactionNotes(transactionNotes: UnitTransactionNotes): Observable<UnitTransactionNotes> {
    return this.baseEndpoint$.
      pipe(
        switchMap(baseUrl =>
          this.http.putTyped<UnitTransactionNotes>(`${baseUrl}/transactionNote/update`, transactionNotes)
        )
      );
  }

  public deleteTransactionNotes(transactionNoteId: number): Observable<void> {
    return this.baseEndpoint$.pipe(
      switchMap(baseUrl => this.http.deleteTyped<void>(
        `${baseUrl}/transactionNote/${transactionNoteId}`)));
  }
}
